//
//
//
//
//
//
//
//
//
//
//

import TheDocumentContent from "@/components/documents/TheDocumentContent";

export default {
  name: "TheDocument",
  components: {TheDocumentContent},
  data() {
    return {
      type: 'concreteDoc',
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapState} from "vuex";
import Loader from "@/components/main/Loader";
import TheRequestForm from "@/components/certificates/TheRequestForm";
import TheDeclinedRequest from "@/components/certificates/TheDeclinedRequest";
import TheApprovedRequest from "@/components/certificates/TheApprovedRequest";
import router from "@/router";

export default {
  name: "TheCertificateRequest",
  components: {TheApprovedRequest, TheDeclinedRequest, TheRequestForm, Loader},
  data() {
    return {
      showSuccess: false,
    }
  },
  methods: {
    setShowSuccess(value) {
      this.showSuccess = Boolean(value)
    }
  },
  beforeMount(){
      if (!this.isAllowDsRequest) {
        router.push({path: '/'})
      }
  },
  computed: {
    ...mapState([
      'personalCertificate',
      'personalCertificateLoading',
        'dsRequest',
    ]),
    ...mapGetters([
      'isDsRequestWaitStatus',
      'isDsRequestRejectedStatus',
      'isDsRequestApprovedStatus',
      'isDsApprovedStatus',
      'isAllowDsRequest',
      'isInWaitDsRequestExist'
    ])
  }
}

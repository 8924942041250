//
//
//
//
//
//
//

export default {
name: "DeclinedIcon"
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "RadioAnswer",
  props: {
    question: Object,
    setAnswer: Function,
    userAnswers: Object,
    rightAnswers: Object,
    isTestPassed: Boolean,
  },

  data() {
    return {
      answers: []
    }
  },

  mounted() {
    if (this.question && this.question.ANSWERS) {
      let answers = [];
      let allAnswers = this.question.ANSWERS;

      allAnswers.forEach((answer) => {
        let isActive = false

        if (this.userAnswers && this.userAnswers[this.question.ID] && this.userAnswers[this.question.ID] === answer) {
          isActive = true
        }

        answers.push({
          active: isActive,
          answer: answer
        })
      })

      this.answers = answers
    }
  },
  methods: {
    click(index) {
      if (this.isTestPassed) {
        return
      }

      let answers = this.answers
      let answer = ''

      answers.forEach((answer) => {
        answer.active = false
      })

      if (answers[index]) {
        answers[index].active = true
        answer = answers[index].answer
      }
      
      this.answers = answers
      answer && this.setAnswer(this.question.ID, answer)
    }
  },
  computed: {
    rightAnswer() {
      if (!this.question || !this.question.RIGHT_ANSWERS[0]) {
        return ''
      }

      return this.question.RIGHT_ANSWERS[0]
    },
  }
}

//
//
//
//

import {mapActions} from 'vuex'

export default {
  name: "Item",
  props: {
    item: Object,
    testId: Number
  },
  methods: {
    ...mapActions([
        'getPaperQuestions'
    ])
  },
  computed: {}
}

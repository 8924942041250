//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import utils from "@/utils";
import RvrModal from "@/components/documents/modals/RvrModal";
import {mapState} from "vuex";
import SendToRvrBtn from "@/components/documents/btns/SendToRvrBtn";
import rvr from "@/document/rvr";

export default {
  name: "ActionsCard",
  components: {RvrModal, SendToRvrBtn},
  props: {
    document: Object,
    documentType: String,
    getDocuments: Function
  },
  data() {
    return {
      isRvrModal: false,
    }
  },
  methods: {
    showRvrModal() {
      this.isRvrModal = true
      utils.addModalOpenClass()
    },
    hideRvrModal() {
      this.isRvrModal = false
      utils.removeModalOpenClass()
    }
  },
  computed: {
    ...mapState([
      'allowSendToTrudVsem',
    ]),
    allowSendToTrudVsemForWorker() {
      return this.document.external && ((typeof this.document.workerIsBasicSigner === 'undefined') || this.document.workerIsBasicSigner === false);
    },
    isSendedToRvr() {
      return rvr.documentSendedToRvr(this.document)
    }
  }
}

//
//
//
//
//
//

export default {
  name: "ArrowIcon"
}

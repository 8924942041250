//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ApprovedIcon from "@/components/icons/ApprovedIcon";
import TheFileInput from "@/components/files/TheFileInput";
import ScanRequestIcon from "@/components/icons/ScanRequestIcon";
import api from "@/api/api";
import notification from "@/notification/notification";
import {mapActions, mapMutations, mapState} from "vuex";
import $ from "jquery";
import utils from "@/utils";
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    name: "TheRequestForm",
    components: {ScanRequestIcon, TheFileInput, ApprovedIcon, VueCountdown},
    props: {
        setShowSuccess: Function
    },
    data() {
        return {
            agreeWithPdPolicy: false,
            agreeWithOffer: false,
            agreeWithOrder: false,
            scanFiles: [],
            maxFilesCount: 10,
            maxSize: '10MB',
            currentStage: 'send-code',
            codeValue: '',
            oldUserEmail: "",
            oldUserPhone: "",
            currentDsEmail: "",
            currentDsPhone: "",
            newDsEmail: "",
            newDsPhone: "",
            timerValue: 30,
            canSendSms: false
        }
    },
    methods: {
        setScanFiles(files) {
            this.scanFiles = files
        },
        checkIsEmpty(files, error) {
            if (!files || !files.length) {
                notification.error(error)
                return true
            }

            return false
        },
        onCodeTimerEnd() {
          this.canSendSms = true;
        },
        validateEmail(email) {
            const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(email) == false) {
                notification.error(this.$t('certificate.incorrect_email'))
                return true
            }

            return false
        },
        save() {
            this.getUserProfile()
            if (this.checkIsEmpty(this.scanFiles, this.$t('certificate.empty_scan_files'))
                || this.checkIsEmpty(this.currentDsPhone, this.$t('certificate.empty_phone'))
                || this.checkIsEmpty(this.currentDsEmail, this.$t('certificate.empty_email'))
            ) {
                return
            }

            const data = new FormData();

            data.append("action", 'EducationNewDsRequest');
            data.append("pd_policy_agreement", '1');
            data.append("offer_agreement", '1');
            data.append("order_agreement", '1');

            for (let scanFile of this.scanFiles) {
                if (!this.isValidTypeFile(scanFile.file)) {
                    notification.error(this.$t('certificate.incorrect_type_file'))
                    return;
                }
                data.append("request_scan[]", scanFile.file);
            }

            data.append("phone", this.currentDsPhone);
            data.append("email", this.currentDsEmail);

            if (this.codeValue) {
                data.append("code", this.codeValue);
                this.hideEmailPopup();
                this.hidePhonePopup();
            } else {
                this.setNewDsEmail(this.currentDsEmail);
                this.setNewDsPhone(this.currentDsPhone);
                this.sendCode();
                this.setStage('confirm-code-add-ds');
                return;
            }


            const response = api.addCertificate(data)

            this.setPersonalCertificateLoading(true)

            response.then(res => {
                if (res.data && res.data.result && res.data.result.id) {
                    this.setShowSuccess(true)
                    this.getCertificate()
                    this.getDsRequest()
                } else {
                    this.setPersonalCertificateLoading(false)
                }
            }).catch(() => {
                this.setPersonalCertificateLoading(false)
                notification.error(this.$t('errors.undefined_error'))
            })
        },
        isValidTypeFile(file) {
            const allowedTypes = [
                'application/pdf',
                'image/jpeg',
                'image/jpg',
                'image/heif',
                'image/webp',
                'image/bmp',
                'image/gif',
                'image/png',
                'image/tiff',
                'image/pcx',
                'image/tga',
                'image/svg',
                'image/svg+xml',
                ""
            ];

            if (allowedTypes.indexOf(file.type) == -1) {
                return false;
            }
            return true;
        },
        addEmail() {
            $('#modalAddEmail').modal('show')
            if (this.currentDsEmail) {
                this.setOldUserEmail(this.currentDsEmail)
            } else if (this.currentUser.name) {
                this.setOldUserEmail(this.currentUser.email)
            }
        },
      addPhone() {
        $('#modalAddPhone').modal('show')
        if (this.currentDsPhone) {
          this.setOldUserPhone(this.currentDsPhone)
        } else if (this.currentUser.name) {
          this.setOldUserPhone(this.currentUser.phone)
        }
      },
        downloadScanFile() {
            const data = {
                email: this.currentDsEmail,
            },
            response = api.getDsRequestScanFile(data);

            response.then(data => {
                if (data.data && data.data.error && data.data.errorStr) {
                    notification.error(data.data.errorStr)
                }

                console.log(data);
                if (data.data && data.data.result.url) {
                    let link = document.createElement('a');
                    link.href = data.data.result.url;
                    link.target = '_blank';
                    link.click()
                }
            })
        },
        sendCode() {
            if (this.validateEmail(this.newDsEmail)) {
                return
            }

            const data = {
                email: this.newDsEmail,
            }

            const response = api.addDsEmail(data);

            response.then(data => {
                if (data.data && data.data.error && data.data.errorStr) {
                    notification.error(data.data.errorStr)
                }
                if (data.data && data.data.result && (data.data.result.result === true) && data.data.result.code) {
                    this.setCodeValue(data.data.result.code);
                    this.save();
                }
                if (data.data && data.data.result && (data.data.result.result === 'needConfirm')) {
                    if (!this.isVisibleThisStage('confirm-code-add-ds')) {
                        this.setCodeValue('');
                        this.setStage('confirm-code');
                    }
                    $('#modalAddEmail').modal('show');
                }
            });
        },
      sendSmsCode() {
        if (!utils.validatePhone(this.newDsPhone)) {
          notification.error(this.$t('certificate.incorrect_phone'))
          return;
        }

        const data = {
          phone: this.newDsPhone,
        }

        const response = api.addDsPhone(data);

        response.then(data => {
          if (data.data && data.data.error && data.data.errorStr) {
            notification.error(data.data.errorStr)
            return
          }
          this.canSendSms = false;
          if (data.data && data.data.result && (data.data.result.result === true) && data.data.result.code) {
            this.setCodeValue(data.data.result.code);
            this.save();
          }
          if (data.data && data.data.result && (data.data.result.result === 'needConfirm')) {
            if (!this.isVisibleThisStage('confirm-code-add-ds')) {
              this.setCodeValue('');
              this.setStage('confirm-code');
            }
            $('#modalAddPhone').modal('show');
          }
        });
      },
        confirmCode() {
            if (this.codeValue) {
                let response = this.confirmDsEmail(this.codeValue)

                response.then(res => {
                    if (res.data && res.data.error && res.data.errorStr) {
                        notification.error(res.data.errorStr)
                    }

                    if (res.data && res.data.result && (res.data.result.success === true)) {
                        this.setCurrentDsEmail(this.newDsEmail);
                        this.setStage('success');
                        notification.success(this.$t('settings.email_saved'))
                    }
                });
            }
        },
      confirmSmsCode() {
        if (this.codeValue) {
          let response = this.confirmDsPhone(this.codeValue)

          response.then(res => {
            if (res.data && res.data.error && res.data.errorStr) {
              notification.error(res.data.errorStr)
            }

            if (res.data && res.data.result && (res.data.result.success === true)) {
              this.setCurrentDsPhone(this.newDsPhone);
              this.setStage('success');
              notification.success(this.$t('settings.phone_saved'))
            }
          });
        }
      },
        setStage(stage) {
            this.currentStage = stage;
        },
        isVisibleThisStage(stage) {
            if (this.currentStage == stage) {
                return true;
            }
            return false;
        },
        hideEmailPopup() {
            this.setStage('send-code')
            this.getUserProfile()
            $('#modalAddEmail').modal('hide')
            this.setOldUserEmail(this.currentDsEmail);
        },
      hidePhonePopup() {
        this.setStage('send-code')
        this.getUserProfile()
        $('#modalAddPhone').modal('hide')
        this.setOldUserPhone(this.currentDsPhone);
      },
        setOldUserEmail(email) {
            this.oldUserEmail = email
        },
        setOldUserPhone(value) {
            this.oldUserPhone = value
        },
        setCurrentDsEmail(email) {
            this.currentDsEmail = email
        },
        setCurrentDsPhone(value) {
          this.currentDsPhone = value
        },
        setNewDsEmail(email) {
            this.newDsEmail = email
        },
        setNewDsPhone(value) {
          this.newDsPhone = value
        },
        setCodeValue(val) {
            this.codeValue = val;
        },
        padStart(value, length, chars) {
          let str = value.toString();
          while (str.length < length) str = chars + str;
          return str;
        },
        ...mapActions([
            'getCertificate',
            'confirmDsEmail',
            'confirmDsPhone',
            'getUserProfile',
            'getDsRequest',
        ]),
        ...mapMutations([
            'setPersonalCertificateLoading'
        ])
    },
    beforeMount() {
        if (this.isEmail) {
            this.setCurrentDsEmail(this.currentUser.email);
        }
      if (this.isPhone) {
        this.setCurrentDsPhone(this.currentUser.phone);
      }
    },
    computed: {
      isEmail() {
        if (this.currentUser.email || this.oldUserEmail) {
          return true;
        }
        return false
      },
      isPhone() {
        if (this.currentUser.phone || this.oldUserPhone) {
          return true;
        }
        return false
      },
        ...mapState([
            'certificatePdPolicyFileLink',
            'certificateOfferFileLink',
            'certificateOrderFileLink',
            'currentUser',
        ])
    },
    directives: {
        passport: function (el) {
            el.oninput = function (e) {
                if (!e.isTrusted) {
                    return;
                }

                const x = this.value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,6})/);
                this.value = x[1] + " " + x[2];
                el.dispatchEvent(new Event('input'));
            }
        },
    }
}

//
//
//
//
//
//
//
//
//
//
//

import Material from "./Material";

export default {
  name: "Materials",
  components: {Material},
  props: {
    materials: Array,
    statusClasses: Object,
    course: Object
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ApprovedIcon from "@/components/icons/ApprovedIcon";
export default {
  name: "TheApprovedRequest",
  components: {ApprovedIcon},
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapGetters } from 'vuex';
import { debounce } from 'lodash';

import Input from '@/components/core/Input';
import SubSection from './SubSection.vue';

export default {
  name: "SubSections",
  components: {
    SubSection,
    Input
  },
  data() {
    return {
      subsections: (this.section && this.section.CHILDREN) ? this.section.CHILDREN : this.$store.state.trialTests.CHILDREN.find(test => test.ID === this.$route.params.id).CHILDREN,
      isEmpty: false,
    }
  },
  computed: {
    hasSubsection(){
      return Boolean(this.subsections.length);
    },
    ...mapGetters(['normalisedSearch'])
  },
  updated() {
    if (this.$refs.sub && !this.$refs.sub.children.length)
      this.isEmpty = true;
    else this.isEmpty = false;
  },
  methods: {
    handleInput:
      debounce(function (search) {
        this.setSearchTraining(search);
      }, 500),
    ...mapMutations(['setSearchTraining']),
    handleClear() {
      this.search = '';
      this.setSearchTraining('');
    },
    filterSubsections(subsection) {
      return this.normalisedSearch
        ? subsection.CHILDREN.filter(item => item.NAME.toLowerCase().includes(this.normalisedSearch)).length || subsection.NAME.toLowerCase().includes(this.normalisedSearch) 
        : subsection;
    },
    filterSection(section) {
      return (section.NAME.toLowerCase().includes(this.normalisedSearch)) ? section : { ...section, CHILDREN: section.CHILDREN.filter(subsection => subsection.NAME.toLowerCase().includes(this.normalisedSearch)) };
    },
  },  
}

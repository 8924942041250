//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'bootstrap/js/dist/collapse';
import $ from 'jquery';  
import { mapGetters } from 'vuex';

import ChevronDownIcon from "@/components/icons/ChevronDownIcon";

export default {
  name: 'SubSection',
  props: ['section', 'collapsed'],
  components: {
    ChevronDownIcon,
  },
  data() {
    return {        
      subsections: (this.section && this.section.CHILDREN) ? this.section.CHILDREN : this.$route.params.children,
      isCollapsed: false,
    }
  },
  computed: {
    hasSubsections(){
      return Boolean(this.subsections.length);
    },
    isLastSubSection() {
      return this.subsections && this.subsections.length && this.subsections[0].TYPE === "folder" 
        && this.subsections[0].CHILDREN && this.subsections[0].CHILDREN[0].TYPE === "item";
    },
    filteredSubSection() {
      if (this.section.NAME.toLowerCase().includes(this.normalisedSearch)) return this.subsections;
      return this.subsections.filter(subsection => subsection.NAME.toLowerCase().includes(this.normalisedSearch));
    },
    ...mapGetters(['normalisedSearch'])
  },
  methods: {
    hasSubsection(subsection) {
      return Boolean(subsection && subsection.length > 0);
    },
    hasNotLastSubsection(subsection) {
      return subsection.CHILDREN && subsection.CHILDREN.length && subsection.CHILDREN[0] === "folder";
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    highlightText(text, search) {
      if (search.length) {
        if (this.filteredSubSection.length) {
          $(`#collapse${this.section.ID}`).collapse("show");
          let normalisedSearch = search.replaceAll('.', '\\.');
          return text.replaceAll(new RegExp(normalisedSearch,'gi'), "<span style='background-color: yellow'>$&</span>");
        }
        else {
          $(`#collapse${this.section.ID}`).collapse("hide");
          return text;
        }
      }
      else {
        $(`#collapse${this.section.ID}`).collapse("hide");
        return text;
      }
    }, 
  },
  watch: {
    normalisedSearch(val, oldVal) {
      if (val === "" && oldVal.length) this.isCollapsed = false;
    }
  },
  mounted(){
    if (!this.collapsed) {
      this.toggleCollapse();
      $(`#collapse${this.section.ID}`).collapse("show");
    }
  }
}

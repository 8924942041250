//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable'

export default {
  name: "CouplesAnswer",
  props: {
    question: Object,
    setAnswer: Function,
    userAnswers: Object,
    rightAnswers: Object,
    isTestPassed: Boolean,
  },

  data() {
    return {
      coupleNames: [],
      coupleValues: [],
    }
  },

  mounted() {
    if (this.question && this.question.ANSWERS) {
      let answers = []
      let names = []

      let allAnswers = this.question.ANSWERS

      if (this.userAnswers && this.userAnswers[this.question.ID]) {
        let userAnswers = this.userAnswers[this.question.ID]
        
        for (let index in userAnswers) {
          let item = userAnswers[index]
          let nameItem = {
            name: item.KEY,
          }

          let answerItem = {
            answer: item.VALUE
          }

          if (this.question.RIGHT_ANSWERS) {
            nameItem.active = false
            answerItem.active = false

            for (let index in this.question.RIGHT_ANSWERS) {
              let rightAnswer = this.question.RIGHT_ANSWERS[index]

              if (rightAnswer.KEY === item.KEY && rightAnswer.VALUE === item.VALUE) {
                nameItem.active = true
                answerItem.active = true
              }
            }
          }

          names.push(nameItem)
          answers.push(answerItem)
        }
      } else {
        for (let index in allAnswers) {
          let item = allAnswers[index]
          names.push({
            name: item.KEY
          })

          answers.push({
            answer: item.VALUE
          })
        }
      }

      this.coupleValues = answers
      this.coupleNames = names

      if (!this.userAnswers || !this.userAnswers[this.question.ID]) {
        this.saveUserAnswers()
      }
    }
  },
  methods: {
    change() {
      this.saveUserAnswers()
    },
    saveUserAnswers() {
      let answers = []

      for (let index in this.coupleNames) {
        answers.push({
          KEY: this.coupleNames[index].name
        })
      }

      for (let index in this.coupleValues) {
        if (answers[index]) {
          answers[index].VALUE = this.coupleValues[index].answer
        }
      }

      this.setAnswer(this.question.ID, answers)
    }
  },
  components: {draggable},
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '/app.config'
export default {
  name: "AgreeCheckbox",
  props: {
    setIsAgree: Function,
    error: Boolean,
    setError: Function
  },
  watch: {
    isAgreeOfferta() {
      this.setAgree()
    },
    isAgreePersonal() {
      this.setAgree()
    },
  },
  methods: {
    setAgree() {
      this.setIsAgree(this.isAgreeOfferta && this.isAgreePersonal)
    }
  },
  data() {
    return {
      isAgreeOfferta: false,
      isAgreePersonal: false,
      config
    }
  }
}

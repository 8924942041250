//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';

import Loader from '@/components/main/Loader';
import defaultLogo from "@/assets/img/trainings/logo.svg";
import BreadCrumb from '@/components/icons/BreadCrumb';

export default {
  name: 'Section',
  components: {Loader, BreadCrumb},
  data() {
    const imageUrl = this.$route.params.section && this.$route.params.section.DETAIL_PICTURE && this.$route.params.section.DETAIL_PICTURE.lg;
    return {
      section: this.$route.params.section,
      subsections: this.getChildren,
      selectedSubsection: '',
      defaultLogo: defaultLogo,
      imageUrl: imageUrl,
      image: {backgroundImage: `linear-gradient(0deg, #F4F4F4 0%, rgba(244, 244, 244, 0.00) 82.27%), url(${imageUrl})`},
      isCollapsed: this.collapsed,
      test: '',
    }
  },
  computed: {
    hasSubsections(){
      return Boolean(this.subsections.length);
    },
    isLastSubSection() {
      return this.subsections && this.subsections.length && this.subsections[0].TYPE === "folder" && this.subsections[0].CHILDREN && this.subsections[0].CHILDREN[0].TYPE === "item";
    },
    getSection() {
      return this.$store.state.trialTests.CHILDREN.find(test => test.ID === this.$route.params.id);
    }, 
    getChildren() {
      return this.getSection.CHILDREN;
    },
  },
  methods: {
    hasSubsection(subsection) {
      return Boolean(subsection && subsection.length > 0);
    },
    hasNotLastSubsection(subsection) {
      return subsection.CHILDREN && subsection.CHILDREN.length && subsection.CHILDREN[0] === "folder";
    },
    ...mapMutations(['setSearchTraining']),
  },
  destroyed() {
    this.setSearchTraining('');
  },
  watch: {
    $route(route) {
      if (route.params.subsectionid) {
        this.selectedSubsection = this.getChildren.find(subsection => route.params.subsectionid === subsection.ID);
      }
      else this.selectedSubsection = '';
      if (route.params.testid) {
        this.test = this.selectedSubsection.CHILDREN.find(test => test.ID === route.params.testid).NAME;
      }
      else this.test = '';
    }
  },
  created() {
    if (this.$route.params.subsectionid) {
        this.selectedSubsection = this.getChildren.find(subsection => this.$route.params.subsectionid === subsection.ID);
      }
      else this.selectedSubsection = '';
      if (this.$route.params.testid) {
        this.test = this.selectedSubsection.CHILDREN.find(test => test.ID === this.$route.params.testid).NAME;
      }
      else this.test = '';
  }
}

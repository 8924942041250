export default {
  /**
   *
   * @param state
   * @param worker
   */
  setWorker: function (state, worker) {
    state.worker = worker;
  },
  /**
   *
   * @param state
   * @param statistic
   */
  setStatistic: function (state, statistic) {
    state.stats = statistic;
  },
  /**
   *
   * @param state
   * @param history
   */
  setHistory: function (state, history) {
    state.history = history;
  },
  /**
   *
   * @param state
   * @param courses
   */
  setCourses: function (state, courses) {
    state.courses = courses;
  },
  /**
   *
   * @param state
   * @param course
   */
  setCourse: function (state, course) {
    state.course = course;
  },
  /**
   *
   * @param state
   * @param error
   */
  setCourseError: function (state, error) {
    state.courseError = error;
  },
  /**
   *
   * @param state
   * @param tests
   */
  setTests: function (state, tests) {
    state.tests = tests;
  },
  /**
   *
   * @param state
   * @param lessons
   */
  setLessons: function (state, lessons) {
    state.lessons = lessons;
  },
  /**
   *
   * @param state
   * @param documents
   */
  setDocuments: function (state, documents) {
    state.documents = documents;
  },
  /**
   *
   * @param state
   * @param email
   */
  setEmail: function (state, email) {
    state.email = email;
  },
  /**
   *
   * @param state
   * @param code
   */
  setCode: function (state, code) {
    state.code = code;
  },
  /**
   *
   * @param state
   * @param error
   */
  setEmailError: function (state, error) {
    state.emailError = error;
  },
  /**
   *
   * @param state
   * @param error
   */
  setCodeError: function (state, error) {
    state.codeError = error;
  },
  /**
   *
   * @param state
   * @param time
   */
  setStartTime: function (state, time) {
    state.startTime = time;
  },

  setTestStartTime: function (state, time) {
    state.testStartTime = time;
  },

  /**
   *
   * @param state
   * @param value
   */
  setIsStartVideo: function (state, value) {
    state.startSaveVideo = value;
  },

  /**
   *
   * @param state
   * @param token
   */
  setToken: function (state, token) {
    state.token = token;
  },

  /**
   *
   * @param state
   * @param settings
   */
  setSettings: function (state, settings) {
    state.pingTime = settings.PING_PERIOD || state.DEFAULT_PING_PERIOD
    state.maxPingTime = settings.PING_NOT_ACTIVE_PERIOD || state.DEFAULT_NOT_ACTIVE_PERIOD
    state.showNotActivePopupTime = settings.PING_IS_ACTIVE_POPUP_PERIOD || state.DEFAULT_NOT_ACTIVE_POPUP_PERIOD
    state.showDocumentsFirst = Boolean(settings.HAS_ACCESS_TO_DOCUMENTS && !settings.HAS_ACCESS_TO_EDUCATION)
    state.hasAccessToSign = settings.ALLOW_DIGITAL_SIGN
    state.hasAvailableDsSlot = true
    state.allowSendToTrudVsem = settings.HAS_ACCESS_TO_TRUD_VSEM
    state.certificateAgreeFileLink = settings.LINK_TO_RULES_FOR_DS_REQUEST
    state.certificateRequestFileLink = settings.TEMPLATE_FOR_DS_REQUEST
    state.certificatePdPolicyFileLink = settings.DS_REQUEST_LINK_TO_PD_POLICY
    state.certificateOfferFileLink = settings.DS_REQUEST_LINK_TO_OFFER
    state.certificateOrderFileLink = settings.DS_REQUEST_LINK_TO_ORDER
  },

  /**
   * @param state
   * @param value
   */
  setShowCourseLoader: function (state, value) {
    state.showCourseLoader = value;
  },

  /**
   * @param state
   * @param value
   */
  setShowDocumentsLoader: function (state, value) {
    state.showDocumentsLoader = value;
  },

  setExternalDocumentLoading: function (state, value) {
    state.externalDocumentLoading = value;
  },

  setWorkerId: function (state, value) {
    state.workerId = value;
  },

  setPin: function (state, value) {
    state.pin = value;
  },

  setTestDuration: function (state, value) {
    state.testDuration = value;
  },

  setTrainingTest: function (state, value) {
    state.trainingTest = value;
  },

  setDocParamsToDownload: function (state, value) {
    state.docParamsToDownload = value
  },

  setStudents: function (state, value) {
    state.students = value;
  },

  setCurrentStudent: function (state, value) {
    state.currentStudent = value;
  },

  setCurrentUser: function (state, value) {
    state.currentUser = value;
  },

  setTimezones: function(state, value) {
    state.timezones = value;
  },

  toggleIsProfileModal: function (state) {
    state.isProfileModal = !state.isProfileModal
  },

  hideProfileModal: function (state) {
    state.isProfileModal = false
  },

  hideUserModal: function (state) {
    state.isUserModal = false
  },

  toggleIsUserModal: function (state) {
    state.isUserModal = !state.isUserModal;
  },

  toggleMobileDocContextMenu: function (state) {
    state.mobileDocContextMenu = !state.mobileDocContextMenu;
  },

  setAuthMethod: function (state, value) {
    state.authMethod = value;
  },

  setAuthCodeSendTimeout: function (state, value) {
    state.authCodeSendTimeout = value;
  },

  setEmptyData(state) {
    localStorage.removeItem(state.storageStudentIdKey)
    state.courses = []
    state.currentStudent = {}
    state.currentUser = {}
    state.stats = {}
    state.worker = {}
    state.students = {}
    state.history = []
    state.courses = []
    state.documents = []
    state.tests = []
    state.trainingTest = {}
    state.authMethod = "phone"
  },

  setCourseRestricted: function (state, value) {
    state.courseRestricted = value;
  },

  setIsModalOpen(state, value) {
    state.isModalOpen = value
  },

  setPersonalCertificate(state, value) {
    state.personalCertificate = value
  },

  setDsRequest(state, value) {
    state.dsRequest = value
  },

  setPersonalCertificateLoading(state, value) {
    state.personalCertificateLoading = value
  },

  setIdDocuments(state, value) {
    state.idDocuments = value;
  },

  setProfile(state, student, getStudentInfo) {
    if (student.ID !== state.currentStudent.ID) {
      state.currentStudent = student
      if (typeof getStudentInfo === 'function') {
        getStudentInfo()
      }
      state.courses = [];
      state.documents = [];
      state.trainingTest = {};

      localStorage.setItem(state.storageStudentIdKey, student.ID)
    }
  },

  setSearchTraining(state, value) {
    state.searchTraining = value;
  },

  setTrialTests(state, tests) {
    state.trialTests = tests;
  },

  setTrialTestLoading(state, status) {
    state.isTrialTestLoading = status;
  },

  setTrainingSessionRun(state, status) {
    state.isTrainingSessionRun = status;
  },

  setExternalDocuments(state, documents) {
    state.externalDocuments = documents;
  },

  setExternalDocumentsLoading(state, status) {
    state.externalDocumentsLoading = status;
  },

  setPreviousRoute(state, route) {
    state.previousRoute = route;
  },

  setCentryState(state, status) {
    state.isCentriConnected = status;
  }
}
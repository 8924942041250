//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PayrollModal from "@/components/documents/modals/PayrollModal";
import $ from "jquery";
export default {
  name: "PersonnelHeaderBlock",
  components: {PayrollModal},
  props: {
    documentsLength: Number,
    setShowPreviewLoader: Function
  },
  methods: {
    showPayrollModal() {
      $('#modalPayroll').modal('show')
    }
  }
}

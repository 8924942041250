//
//
//
//
//
//
//

export default {
name: "FileDeleteIcon"
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TextAnswer",
  props: {
    question: Object,
    setAnswer: Function,
    userAnswers: Object,
    rightAnswers: Object,
    isTestPassed: Boolean,
  },

  data() {
    return {
      answer: ''
    }
  },

  watch: {
    question() {
      if (this.userAnswers && this.userAnswers[this.question.ID]) {
        this.answer = this.userAnswers[this.question.ID]
      } else {
        this.answer = ''
      }
    }
  },

  mounted() {
    if (this.userAnswers && this.userAnswers[this.question.ID]) {
      this.answer = this.userAnswers[this.question.ID]
    }
  },

  methods: {
    keyUp() {
      this.setAnswer(this.question.ID, this.answer)
    }
  },
  computed: {
    rightAnswer() {
      if (!this.question) {
        return ''
      }

      return this.question.RIGHT_ANSWERS
    }
  }
}

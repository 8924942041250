import RecordRTC from 'recordrtc';
import api from "../api/api";

const TIME_DELAY = 3000;
const MediaStreamRecorder = RecordRTC.MediaStreamRecorder;

function isIOS() {
  return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

/**
 * On some of the iOS devices, the video gets too big so that Safari fails to process the file and returns 0-byted one without failing (which is undocumented and, obviously, an error).
 * As a workaround, we reduce the quality of a video in the native recording API (which is used by the `videojs-record` and `RecordRTC` internally) so that it won't get to this size.
 *
 * TODO: remove it when https://bugs.webkit.org/show_bug.cgi?id=85851 is resolved by Apple.
 */
if (isIOS()) {
  const DefaultMediaRecorder = window.MediaRecorder;
  const kb = 8 * 1024;
  const preferredBitRatePerSecond = 100 * kb;
  window.MediaRecorder = class extends DefaultMediaRecorder {
    constructor(stream, options) {
      super(stream, {
        ...options,
        audioBitsPerSecond: preferredBitRatePerSecond,
        videoBitsPerSecond: preferredBitRatePerSecond,
      });
    }
  }
}

/**
 *
 * @param sessionId
 * @param testId
 * @param image
 */
function sendPhotoToServer(sessionId, testId, image) {
  if (testId && image) {
    api.savePhoto(sessionId, testId, image);
  }
}

/**
 *
 * @param testId
 * @param video
 */
function sendVideoToServer(sessionId, testId, video) {
  if (testId && video) {
    api.saveVideo(sessionId, testId, video);
  }
}

function saveVideo(sessionId, testId) {
  let reader = new window.FileReader();
  let blob = recorder.getBlob();
  if (blob && blob.size) {
    reader.readAsDataURL(blob);

    reader.onloadend = function () {
      var base64 = reader.result;
      sendVideoToServer(sessionId, testId, base64);
    };
  }
}

let stopped = false;
const canvas = document.createElement('canvas');
var recorder =  null;
let videoElement = null;
let photoElement = null;
let streamOb = null;
var timeoutId = null

export default {
  async init (videoElem, videoMiniElem, photoElem) {
    videoElement = videoElem;
    photoElement = photoElem;
    
    return await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: "user"
      },
      audio: {
        //autoGainControl: true,
        echoCancellation: true,
        noiseSuppression: false,
        volume: 1
      }
    })
      .then(async function(stream) {
      streamOb = stream;
      stopped = false;
      recorder = RecordRTC(stream, {
        type: 'video',
        recorderType: MediaStreamRecorder,
        disableLogs: true,
      });

      videoElement.srcObject = stream;
      videoElement.play();

      if (videoMiniElem) {
        videoMiniElem.srcObject = stream;
        videoMiniElem.play();
      }

      return true;
    })
      .catch( () =>  {
        throw new Error('Camera error');
      } );
  },
  startVideo(sessionId, testId) {
    stopped = false;

    (function loop() {
      if (recorder) {
        recorder.startRecording();

        timeoutId = setTimeout(function() {
          recorder.stopRecording(function () {
            saveVideo(sessionId, testId)
          });
          if(!stopped) {
            loop();
          }
        }, TIME_DELAY);
      }

    })();
  },
  destroy() {
    if (streamOb) {
      streamOb.getTracks().forEach(function(track) {
        track.stop();
      });
    }
  },
  stopVideo(sessionId, testId) {
    stopped = true;
    if(timeoutId) {
      clearTimeout(timeoutId)
      saveVideo(sessionId, testId)
    }
  },
  takeScreenShot() {
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    canvas.getContext('2d').drawImage(videoElement, 0, 0);
    photoElement.src = canvas.toDataURL("image/jpg");
  },
  saveImage(sessionId, testId) {
    const image = canvas.toDataURL('image/jpeg');
    sendPhotoToServer(sessionId, testId, image);
  }
}
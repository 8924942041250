import store from "@/store";

const documentSendedToRvr = (document) => {
    return Boolean(document.orgSignSendedToTrudVsem)
}

const getParamsForGetLinkRequest = document => {
    return {
        docId: document.id,
        student_id: store.state.currentStudent.ID
    }
}

export default {
    documentSendedToRvr,
    getParamsForGetLinkRequest,
}
//
//
//
//
//
//
//
//
//
//
//
//

import MagnifyingGlassIcon from '@/components/icons/MagnifyingGlassIcon';

export default {
  props: {
    value: String,
    placeholder: String,
    multiline: {
      type: Boolean,
      default: false,
    },
    autofit: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:value'],
  components: {
    MagnifyingGlassIcon
  },
  methods: {
    handleUpdate(event) {
      if (this.multiline && this.autofit) this.$refs.textarea.style.height = `${event.target.scrollHeight}px`;
      this.$emit('input', event.target.value);
      this.$emit('update:value', event.target.value);
    }
  }
}

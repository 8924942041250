//
//
//
//
//
//

export default {
  name: "YoutubeQuestion",
  props: {
    question: Object,
    number: Number,
  }
}

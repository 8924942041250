//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable'

export default {
  name: "OrderAnswer",
  props: {
    question: Object,
    setAnswer: Function,
    userAnswers: Object,
    rightAnswers: Object,
    isTestPassed: Boolean,
  },

  data() {
    return {
      answers: []
    }
  },

  mounted() {
    if (this.question && this.question.ANSWERS) {
      let answers = []

      let allAnswers = this.question.ANSWERS

      if (this.userAnswers && this.userAnswers[this.question.ID]) {
        answers = this.userAnswers[this.question.ID]
      } else {
        answers = allAnswers
      }

      this.answers = answers

      if (!this.userAnswers || !this.userAnswers[this.question.ID]) {
        this.saveUserAnswers()
      }
    }
  },
  methods: {
    change() {
      this.saveUserAnswers()
    },
    saveUserAnswers() {
      let answers = [];

      for (let index in this.answers) {
        let answer = this.answers[index]
        answers.push({
          VALUE: Number(index) + 1,
          KEY: answer.KEY
        })
      }

      this.setAnswer(this.question.ID, answers)
    }
  },
  computed: {
    passedQuestionAnswers() {
      if (!this.question || !this.question.RIGHT_ANSWERS || !this.userAnswers[this.question.ID]) {
        return []
      }

      let userAnswers = this.userAnswers[this.question.ID]

      for (let index in userAnswers) {
        userAnswers[index].active = false

        for (let rightAnswer of this.question.RIGHT_ANSWERS) {
          if (rightAnswer.KEY == userAnswers[index].KEY && rightAnswer.VALUE == userAnswers[index].VALUE) {
            userAnswers[index].active = true
          }
        }
      }

      return userAnswers
    },

    sortedRightQuestionAnswers() {
      let answers = this.question.RIGHT_ANSWERS

      answers.sort(function (a, b) {
        return a.VALUE - b.VALUE
      });

      return answers
    }
  },

  components: {draggable}
}

//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SignerItem",
  props: {
    signerItems: Object,
  }
}

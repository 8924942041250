//
//
//
//

export default {
  name: "PdfMaterial",
  props: {
    src: String
  },
}

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store/index'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import VueFileAgent from 'vue-file-agent';
import FloatingVue  from 'floating-vue';
import VueMobileDetection from "vue-mobile-detection";
import { BootstrapVue } from 'bootstrap-vue';

import './assets/css/main.scss';
import 'floating-vue/dist/style.css'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueFileAgent);
Vue.use(FloatingVue);

import 'bootstrap';
import './assets/css/style.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(VueMobileDetection);
Vue.use(BootstrapVue);

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.yaml$/i);
  const messages = {};

  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  messages: loadLocaleMessages(),
  locale: 'ru',
});

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app');

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery'

export default {
  name: "TestHeader",
  props: {
    questions: Array,
    setQuestion: Function,
    currentIndex: Number,
    isTestPassed: Boolean,
    userAnswers: Object,
    isActive: Function
  },
  data() {
    return {
      scrollNumber: 150,
      scrollTime: 500,
      scrollType: 'swing',
      scrolledToLeft: true,
      scrolledToRight: false,
      isOverflowed: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.updateIsOverflowed()
    }, 500)

    $(window).resize(() => {
      this.updateIsOverflowed()
    })
  },
  methods: {
    scrollLeft() {
      if (this.scrolledToLeft || !this.isOverflowed) {
        return
      }

      this.scrolledToRight = false

      let scrollElem = $('.header-numbers-stage');

      scrollElem.animate({scrollLeft: "-=" + this.scrollNumber}, this.scrollTime, this.scrollType, () => {
        if (!scrollElem.scrollLeft()) {
          this.scrolledToLeft = true
        }
      });
    },
    scrollRight() {
      if (this.scrolledToRight || !this.isOverflowed) {
        return
      }

      this.scrolledToLeft = false

      let scrollElem = $('.header-numbers-stage');

      let scroll = scrollElem.scrollLeft();

      scrollElem.animate({scrollLeft: "+=" + this.scrollNumber}, this.scrollTime, this.scrollType, () => {
        if (scroll === scrollElem.scrollLeft()) {
          this.scrolledToRight = true
        }
      });
    },
    updateIsOverflowed() {
      let scrollElem = $('.header-numbers-stage');

      let elems = scrollElem.find('.header-numbers-box')

      if (!scrollElem || !scrollElem[0] || !elems) {
        return  false
      }

      let elemsWidth = 0;

      elems.each((index, elem) => {
        elemsWidth += $(elem)[0].offsetWidth;
      })

      this.isOverflowed = elemsWidth > scrollElem[0].clientWidth
    },
  },
  computed: {
    statusClasses() {
      let statuses = {}

      for (let questionIndex in this.questions) {
        let questionId = this.questions[questionIndex].ID
        let statusClass = '';

        if (questionIndex == this.currentIndex) {
          statusClass += 'current'
        }

        if (this.isTestPassed) {
          if (this.questions[questionIndex].PASSED) {
            statusClass += ' success'
          } else {
            statusClass += ' danger'
          }
        } else {
          if (this.isActive(questionIndex)) {
            statusClass += ' empty'
          } else {
            statusClass += ' disable'
          }
        }

        statuses[questionId] = statusClass
      }

      return statuses
    },
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FormField",
  props: {
    label: String,
    placeholder: String,
    model: String,
    click: Function,
    error: String,
    type: String,
    className: String,
    isNotNumeric: Boolean,
    validator: Function,
    setValue: Function,
    setError: Function,
  },
  computed: {
    value: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    onInput(value) {
      let val = null
      if (typeof value == 'object' && typeof value.target.value !== "undefined") {
        val = value.target.value
      } else {
        val = value
      }

      if (typeof this.setError === "function" && val === "") {
        this.setError(null)
      }

      if (typeof this.validator !== "function") {
        return
      }

      if (val !== "" && !this.validator(val)) {
        this.setError("true")
      } else {
        this.setError(null)
      }
      this.setValue(val)
    },
  }
}

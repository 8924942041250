//
//
//
//
//
//

export default {
  name: "ImageQuestion",
  props: {
    question: Object,
    url: String,
  },
}

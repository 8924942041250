//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Description",
  props: {
    question: Object
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    toggle() {
      this.active = !this.active
    }
  }
}

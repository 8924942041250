//
//
//
//

export default {
  name: "AudioLesson",
  props: {
    src: String
  }
}

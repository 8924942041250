//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import api from "../../api/api";
import notification from "@/notification/notification";
import {TheMask} from 'vue-the-mask'
import $ from "jquery";
import Loader from "@/components/main/Loader";
import config from '/app.config';
import utils from "@/utils";

export default {
  name: "Content",
  components: {Loader, TheMask },
  data() {
    return {
      login: '',
      name: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      errors: {},
      codeValue: '',
      showLoader: false,
      needAnotherConfirm: false,
      config,
      passwordPolicyEnable: (config.variables && config.variables.passwordPolicyEnable),
      showSendSMS: (config.variables && !config.variables.hideSettingsNotificationsSMS),
      showSettingsPhone: (config.variables && !config.variables.hideSettingsPhone),
    }
  },
  created() {
    if (this.isIdAuthMethod) {
      this.$router.push({
        name: 'courses'
      })
    }

    this.getStudentInfo()
  },
  mounted() {
    $("#modalConfirm").on("hidden", function () {
      alert('close');
    });
  },
  methods: {
    showEmailPopup() {
      $('#modalConfirm').modal('show')
    },
    hideEmailPopup() {
      $('#modalConfirm').modal('hide')
    },
    showPhonePopup() {
      $('#modalPhoneConfirm').modal('show')
    },
    hidePhonePopup() {
      $('#modalPhoneConfirm').modal('hide')
    },
    confirmMail() {
      if (this.codeValue) {
        let response = this.confirmUserEmail(this.codeValue)

        response.then(res => {
          if (res.data && res.data.error && res.data.errorStr) {
            notification.error(res.data.errorStr)
          }

          if (res.data && res.data.result && (res.data.result.success === true)) {
            notification.success(this.$t('settings.email_saved'))
            this.getUserProfile()
            this.hideEmailPopup()
            if(this.needAnotherConfirm) {
              this.codeValue = ""
              this.showPhonePopup()
            }
          }
        });
      }
    },
    confirmPhone() {
      if (this.codeValue) {
        let response = this.confirmUserPhone(this.codeValue)

        response.then(res => {
          if (res.data && res.data.error && res.data.errorStr) {
            notification.error(res.data.errorStr)
          }

          if (res.data && res.data.result && (res.data.result.success === true)) {
            notification.success(this.$t('settings.phone_saved'))
            this.getUserProfile()
            this.hidePhonePopup()
          }
        });
      }
    },
    setInput(event) {
      if (event && event.key == " ") {
        event.preventDefault();
      }
    },
    save(e) {      
      e.preventDefault();
      if (this.currentUser.password && this.currentUser.password.length) {
        if (this.currentUser.password !== this.currentUser.passwordConfirm) {
          this.setError('passwordConfirm', this.$t('settings.password_confirm'));
          return;
        }
        const { valid, errorMessage } = utils.handleValidatePasswordForm(this.currentUser.password, config, this.$t.bind(this));
        if (!valid) {
          this.setError("passwordPolicy", errorMessage);
          return;
        }
      } else {
        this.setError('password', this.$t('settings.password_empty'));
        return
      }
      
      const data = {
        FULL_NAME: this.currentUser.name,
        EMAIL: this.currentUser.email,
        PERSONAL_PHONE: this.currentUser.phone,
        PIN: this.currentUser.password,
        PIN_CONFIRM: this.currentUser.passwordConfirm,
        NOTIFY_BY_EMAIL: this.currentUser.subscribeEmail,
        NOTIFY_BY_SMS: this.currentUser.subscribeSms,
        TIMEZONE: this.currentUser.timezone,
      }

      const response = api.saveUserSettings(data);

      response.then(data => {
        if (data.data && data.data.error && data.data.errorStr) {
          notification.error(data.data.errorStr)
        }

        if (data.data && data.data.result && (data.data.result.result === true)) {
          notification.success(this.$t('settings.saved'))
          this.getUserAndStudents()
        }

        if (
            data.data
            && data.data.result
            && data.data.result.result
            && data.data.result.result["needEmailConfirm"]
        ) {
          this.showEmailPopup()
          if(data.data.result.result["needPhoneConfirm"]) {
            this.needAnotherConfirm = true
          }
        }

        if (
            data.data
            && data.data.result
            && data.data.result.result
            && data.data.result.result["needPhoneConfirm"]
            && !data.data.result.result["needEmailConfirm"]
        ) {
          this.showPhonePopup()
        }
      }).catch(() => {
        notification.error(this.$t('errors.undefined_error'))
      }).finally(() => {
        this.showLoader = false
      })
    },

    clearErrors() {
      this.errors = {}
    },

    setError(name, error) {
      const errors = Object.create(this.errors)
      errors[name] = error

      this.errors = errors
    },

    ...mapActions([
      'getStudentInfo',
      'getUserAndStudents',
      'confirmUserEmail',
      'confirmUserPhone',
      'getUserProfile',
      'getTimezones'
    ]),
    ...mapMutations([
      'setStudents',
      'setCurrentStudent'
    ])
  },
  computed: {
    ...mapState([
      'students',
      'storageStudentIdKey',
      'currentStudent',
      'currentUser',
      'timezones'
    ]),
    ...mapGetters([
      'isIdAuthMethod',
    ])
  },
}

//
//
//
//
//
//
//

import FormField from "@/components/auth/FormField";
import utils from "@/utils";

export default {
  name: "Email",
  components: {FormField},
  props: {
    error: String,
    title: String,
    placeholder: String,
    value: String,
    setValue: Function,
    click: Function,
    setError: Function,
    isNotNumeric: Boolean,
  },
  methods: {
    onInput (value) {
        let val = null
        if (typeof value == 'function' && typeof value.target.value !== "undefined") {
          val = value.target.value
        } else {
          val = value
        }
        if (val === "") {
          this.setError(null)
        }
        if (val !== "" && !utils.validateEmail(val)) {
          this.setError("true")
        } else {
          this.setError(null)
        }
        this.setValue(val)
    },
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MagnifyingGlassIcon",
  props: {
    height: {
      type: Number,
      default: 12,
    },
    width: {
      type: Number,
      default: 12,
    }
  }
}

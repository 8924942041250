import store from "@/store";
import {CERTS_DATE_FORMAT, DS_FORMAT} from "@/sign/consts";
import moment from "moment";
import {extractFunc} from "@/sign/sign";
import Vue from "vue";
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export const getSignParams = (docId, docType, selectedCert, sendToTrudVsem = false, sign = null) => {
    const signParams = {
        docId: docId,
        type: docType,
        certId: selectedCert.SerialNumber,
        validFrom: moment(selectedCert.ValidFromDate).format(CERTS_DATE_FORMAT),
        validTo: moment(selectedCert.ValidToDate).format(CERTS_DATE_FORMAT),
        issuerName: selectedCert.IssuerName,
        publicKey: selectedCert.PublicKey,
        sendToTrudVsem: sendToTrudVsem,
        student_id: store.state.currentStudent.ID,
    }

    if (sign) {
        signParams.sign = sign
        signParams.signatureFormat = DS_FORMAT
    }

    return signParams
}

export const getSignInfo = (signer, app, select = null) => {
    if (!signer) {
        return {}
    }

    if (!select) {
        select = [
            'signer',
            'state',
            'signedAt',
            'certId',
            'validAt',
            'inn',
            'companyName',
            'position',
            'birthDate',
            'passportNum',
            'email',
            'phone',
        ]
    }
    const values = {}

    if (select.includes('signer')) {
        values.signer = {
            name: app.$t('sign_modal.signer'),
            value: (signer.fio)? signer.fio : ((signer.surName || '') + ' ' + (signer.firstName || '') + ' ' + (signer.middleName || '')),
        }
    }

    if (!signer.dsProcessedAt) {
        if( select.includes('state')) {
            values.state = {
                name: app.$t('sign_modal.status'),
                value: app.$t('sign_modal.not_signed'),
            }
        }
    } else if (signer.sign) {
        let subject = signer.sign.subject;

        for (const selectKey in select) {
            const key = select[selectKey]
            switch (key) {
                case 'state':                    
                    values[key] = {
                        name: app.$t('sign_modal.status'),
                        value: signer.signedOnTrudVsem? app.$t('rvr_modal.state') : app.$t('sign_modal.signed'),
                    }
                    break;
                case 'signedAt':
                    values[key] = {
                        name: app.$t('sign_modal.signed_at'),
                        value: signer.dsProcessedAt || ''
                    }
                    break;
                case 'certId':
                    values[key] = {
                        name: app.$t('sign_modal.certificate'),
                        value: signer.sign.certId || '',
                    }
                    break;
                case 'validAt':
                    values[key] = {
                        name: app.$t('sign_modal.valid_at'),
                        value: (signer.sign.validFrom && signer.sign.validTo) ? ('с ' + signer.sign.validFrom + ' по ' + signer.sign.validTo) : '' ,
                    }
                    break;
                case 'inn':
                    if (signer.sign.isRvrSimple != true) {
                        values[key] = {
                            name: app.$t('sign_modal.inn'),
                            value: signer.sign.inn || '',
                        }
                    }
                    break;
                case 'companyName':
                    values[key] = {
                        name: app.$t('sign_modal.company_name'),
                        value: signer.sign.company || '',
                    }
                    break;
                case 'position': {
                    let position = subject ? extractFunc(signer.sign.subject || '', 'T=') || extractFunc(signer.sign.subject || '', 'positionFullName=') : '';
                    if (position !== '') {
                        values.position = {
                            name: app.$t('sign_modal.position'),
                            value: subject ? extractFunc(signer.sign.subject || '', 'T=') || extractFunc(signer.sign.subject || '', 'positionFullName=') : '',
                        }
                    }
                    break;
                }
                case 'birthDate': {
                    let birthDate = subject ? extractFunc(signer.sign.subject || '', 'birthDate=') : '';
                    if (birthDate !== '') {
                        values.birthDate = {
                            name: app.$t('certificates_modal.birth_date'),
                            value: subject ? extractFunc(signer.sign.subject || '', 'birthDate=') : '',
                        }
                    }
                    break;
                }
                case 'passportNum': {
                    let passportNum = subject ? extractFunc(signer.sign.subject || '', 'passportNum=') : '';
                    if (passportNum !== '') {
                        values.passportNum = {
                            name: app.$t('certificates_modal.passport_num'),
                            value: subject ? extractFunc(signer.sign.subject || '', 'passportNum=') : '',
                        }
                    }
                    break;
                }
                case 'email': {
                    let email = subject ? extractFunc(signer.sign.subject || '', 'email=') : '';
                    if (email !== '') {
                        values.email = {
                            name: app.$t('certificates_modal.email'),
                            value: subject ? extractFunc(signer.sign.subject || '', 'email=') : '',
                        }
                    }
                    break;
                }
                case 'phone': {
                    let phone = subject ? extractFunc(signer.sign.subject || '', 'phone=') : '';
                    if (phone !== '') {
                        values.phone = {
                            name: app.$t('certificates_modal.phone'),
                            value: subject ? extractFunc(signer.sign.subject || '', 'phone=') : '',
                        }
                    }
                    break;
                }
                case 'snils': {
                    if (signer.sign.isRvrSimple == true) {
                        values[key] = {
                            name: app.$t('sign_modal.snils'),
                            value: signer.sign.snils || '',
                        }
                    }
                    break;
                }
                case 'esiaId': {
                    if (signer.sign.isRvrSimple == true) {
                        values[key] = {
                            name: app.$t('sign_modal.esia_id'),
                            value: signer.sign.esiaId || '',
                        }
                    }
                    break;
                }
            }
        }
    }

    return values
}

export const getDocSignerForWorker = (document) => {
    let thatVerySigner = null;

    document.signers.forEach(signer => {
        if (signer.workerId && signer.workerId === store.state.currentStudent.ID) {
            thatVerySigner = signer;
        }
    })

    return thatVerySigner;

}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BIconChevronDown, BIconCheck2 } from "bootstrap-vue";

export default {
  props: {
    question: Object,
    search: String,
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  components: { BIconChevronDown, BIconCheck2 },
  data() {
    return {
      collapseShown: false,
    }
  },
  computed: {
    getTitle() {
      if (this.search.length) {
        let normalisedSearch = this.search.replaceAll('.', '\\.');
        return this.question.NAME.replaceAll(new RegExp(normalisedSearch,'gi'), "<span style='background-color: yellow'>$&</span>");
      }
      return this.question.NAME;
    },
    showBottomBorder() {
      return !this.isLast && !this.collapseShown;
    }
  }
}

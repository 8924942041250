import './cades_plugin'
import store from '../store/index'
import moment from 'moment'

if (store) {
//  console.log(store.state);
}

export const createSign = (certNum, dataToSign) => {
  return new Promise(function (resolve, reject) {
    window.cadesplugin.async_spawn(function* (args) {
      const oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
      yield oStore.Open(window.cadesplugin.CADESCOM_CONTAINER_STORE);

      const oStoreCerts = yield oStore.Certificates;

      let oCertificate = null;

      const certsCount = yield oStoreCerts.Count;

      for (let i = 1; i <= certsCount; i++) {
        let cert = yield oStoreCerts.Item(i)

        if ((yield cert.SerialNumber) == certNum) {
          oCertificate = cert
        }
      }

      if (!oCertificate) {
        let err = "Certificate not found: " + certNum;
        args[1](err);
      }

      //dataToSign = window.btoa(dataToSign);
      let Signature;

      try
      {
        //FillCertInfo_Async(certificate);
        let errormes = "";
        let oSigner;

        try {
          oSigner = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
        } catch (err) {
          errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
          throw errormes;
        }

        const oSigningTimeAttr = yield window.cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
        const CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
        yield oSigningTimeAttr.propset_Name(CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);

        const oTimeNow = new Date();
        yield oSigningTimeAttr.propset_Value(oTimeNow);
        const attr = yield oSigner.AuthenticatedAttributes2;
        yield attr.Add(oSigningTimeAttr);


        const oDocumentNameAttr = yield window.cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
        const CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
        yield oDocumentNameAttr.propset_Name(CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
        yield oDocumentNameAttr.propset_Value("Document Name");
        yield attr.Add(oDocumentNameAttr);

        if (oSigner) {
          yield oSigner.propset_Certificate(oCertificate);
        } else {
          errormes = "Failed to create CAdESCOM.CPSigner";
          throw errormes;
        }

        const oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
        const CADES_BES = 1;
        if (dataToSign) {
          yield oSignedData.propset_ContentEncoding(1);
          yield oSignedData.propset_Content(dataToSign);
          yield oSigner.propset_Options(1);
          //yield oSigner.propset_Options(window.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);
          //yield oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY); //

          //yield oSignedData.propset_Content(dataToSign);

          try {
            Signature = yield oSignedData.SignCades(oSigner, CADES_BES);
            yield oStore.Close();
            return args[0](Signature);
          }
          catch (err) {
            errormes = "Не удалось создать подпись из-за ошибки: " + window.cadesplugin.getLastError(err);
            throw errormes;
          }
        }

      }
      catch(e)
      {
        let err = window.cadesplugin.getLastError(e);
        args[1](err);
      }
    }, resolve, reject);
  });
}

export const getCertificateKepNames = () => {
  return new Promise(function (resolve, reject) {
    window.cadesplugin.async_spawn(function* (args) {
      const certs = []

      let oStore;

      try {
        oStore = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
      } catch (e) {
        args[1]('plugin_not_found');
      }

      try {
        yield oStore.Open(window.cadesplugin.CADESCOM_CONTAINER_STORE);

        const oStoreCerts = yield oStore.Certificates;

        const certsCount = yield oStoreCerts.Count;

        if (certsCount === 0) {
          throw('Certificates not found');
        }

        for (let i = 1; i <= certsCount; i++) {
          let cert = yield oStoreCerts.Item(i)
          let privateKey = yield cert.PrivateKey
          let publicKey = yield cert.PublicKey()
          let algorithm = yield publicKey.Algorithm
          let publicKeyExport = yield cert.Export(0);

          certs.push(
            {
            SubjectName: yield cert.SubjectName,
            IssuerName: extract(yield cert.IssuerName),
            owner: extract(yield cert.SubjectName),
            company: extract(yield cert.SubjectName, 'O='),
            fullName: extract(yield cert.SubjectName, 'SN=') + ' ' + extract(yield cert.SubjectName, 'G='),
            SerialNumber: yield cert.SerialNumber,
            ValidToDate: yield cert.ValidToDate,
            ValidFromDate: yield cert.ValidFromDate,
            Provider: yield privateKey.ProviderName,
            Algorithm: yield algorithm.FriendlyName,
            PublicKey: publicKeyExport,
          })
        }
        yield oStore.Close();
      } catch (e) {
        let err = window.cadesplugin.getLastError(e);
        args[1](err);
      }

      return args[0](certs);
    }, resolve, reject);
  });
}

export const getCertificateNep = () => {
  let cert = {};
    if (store.state && store.state.personalCertificate && store.state.personalCertificate.additional_info) {
      const personalCertificate = store.state.personalCertificate
      cert = {
        SubjectName: personalCertificate.additional_info.subject,
        IssuerName: personalCertificate.additional_info.issuer,
        owner: extract(personalCertificate.additional_info.subject),
        company: personalCertificate.additional_info.subjectObject.organizationFullName || personalCertificate.additional_info.subjectObject.organization,
        fullName: (personalCertificate.additional_info.subjectObject.fioFull || personalCertificate.additional_info.subjectObject.fio) + ' '
            + extract(personalCertificate.additional_info.subject, 'G='),
        birthDate: personalCertificate.additional_info.subjectObject.birthDate,
        SerialNumber: personalCertificate.UF_DS_UID,
        ValidToDate: moment(personalCertificate.UF_EXPIRATION_DATE, 'DD.MM.YYYY HH:mm:ss').toISOString(),
        ValidFromDate: moment(personalCertificate.UF_OUTPUT_DATE, 'DD.MM.YYYY HH:mm:ss').toISOString(),
        Provider: personalCertificate.additional_info.provider,
        Algorithm: personalCertificate.additional_info.algorithm,
        type: 'personal',
        position: personalCertificate.additional_info.subjectObject.positionFullName,
        passportNum: personalCertificate.additional_info.subjectObject.passportNum,
        email: personalCertificate.additional_info.subjectObject.email,
        phone: personalCertificate.additional_info.subjectObject.phone,
        inn: personalCertificate.additional_info.subjectObject.inn,
      };
    }

    return cert;
}

const extract = (from, what = 'CN=') => {
  let certName = "";

  const begin = from.indexOf(what);

  if(begin>=0)
  {
    let end = from.indexOf(', ', begin + what.length);
    while(end > 0) {
      if (checkQuotes(from.substr(begin + what.length, end-begin)))
        break;
      end = from.indexOf(', ', end + 1);
    }

    certName = (end < 0) ? from.substr(begin + what.length) : from.substr(begin + what.length, end - (begin + what.length));
  }

  return certName;
}

export const extractFunc = extract

const checkQuotes = (str) => {
  let result = 0, i = 0

  for (i; i < str.length; i++) if (str[i] === '"')
    result++;
  return !(result % 2);
}


//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapMutations, mapState} from 'vuex';
import Loader from "@/components/main/Loader";
import config from '../../../app.config';
import notification from "@/notification/notification";
import api from "@/api/api";

export default {
  name: "RegistrationVnotConfirm",
  components: {Loader},
  metaInfo() {
    return {
      title: this.title,
    }
  },
  created() {
   if (this.token) {
     this.logout()
   }
    this.initConfirmRegistrationCheck();
  },
  data: function () {
    return {
      error: '',
      config,
      showLoader: false,
      title: this.$i18n.t('auth.title'),
      id: '',
      needCheckCode: false,
      timeOut: 0,
      message: '',
      isConfirmed: false,
    }
  },
  methods: {
    setShowLoader(value) {
      this.showLoader = Boolean(value)
    },
    setNeedCheckCode(value) {
      this.needCheckCode = value
    },
    setError(error = '') {
      this.error = error
    },
    setMessage(message = '') {
      this.message = message
    },
    cleanErrors() {
      this.error = ''
    },
    ...mapActions([
      'login',
      'logout',
      'getUserAndStudents',
      'getStudentInfo',
    ]),
    ...mapMutations([
      'setToken',
      'setAuthMethod',
      'setCurrentStudent',
      'setCourses',
      'setDocuments',
      'setTrainingTest',
        'setProfile',
    ]),
    initConfirmRegistrationCheck() {
      const id = this.$route.query.id
      const code = this.$route.query.code

      if (!id || !code) {
        notification.error(this.$i18n.t('vnot.reg.bad_link_to_confirm'))
      }

      const data = {
        id: id,
        code: code,
      }

      const response = api.vnotRegistrationsConfirm(data);

      response.then(async (data) => {
        if (data.data && data.data.error && data.data.errorStr) {
          return
        }

        if (data.data && data.data.result && data.data.result.result && data.data.result.result.token) {
          let token = data.data.result.result.token;
          this.setToken(token);
          this.setProfile(data.data.result.result.student, null)
          await this.getUserAndStudents()
          await this.getStudentInfo()
          localStorage.edu_app_token = token;
          await this.$router.push({
            name: 'courses'
          })
        } else {
          console.log('1')
          notification.error(this.$t('errors.undefined_error'))
        }
      }).catch((e) => {
        console.log(e)
        notification.error(this.$t('errors.undefined_error'))
      }).finally(() => {
        this.showLoader = false
      })
    },
  },
  computed: {
    ...mapState([
      'token',
      'authMethod',
      'idAuthMethod',
      'phoneAuthMethod',
      'storageStudentIdKey',
    ]),
  }
}

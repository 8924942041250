//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GetArchiveModal from "@/components/documents/modals/GetArchiveModal";
import api from "@/api/api";
import moment from "moment";
import vSelect from 'vue-select'
import $ from "jquery";
import notification from "@/notification/notification";
import {mapMutations} from "vuex";
import DatePicker from 'vue2-datepicker';
import PersonnelHeaderBlock from "@/components/documents/headers/PersonnelHeaderBlock";
import DropdownSelectDocuments from "@/components/documents/table/DropdownSelectDocuments";

const date = new Date()

export default {
  name: "DocumentsHeaderBlock",
  components: {
    GetArchiveModal,
    vSelect,
    DatePicker,
    PersonnelHeaderBlock,
    DropdownSelectDocuments
  },
  mounted() {
    this.getFilters()
  },
  props: {
    getDocuments: Function,
    getSignRules: Function,
    resetSelected: Function,
    setFilter: Function,
    limit: Number,
    total: Number,
    setRestrictedError: Function,
    isStudentDocsWithSign: Boolean,
    isPersonnelDocuments: Boolean,
    setShowPreviewLoader: Function,
    isDocuments: Boolean,
    documents: Array,
    signRules: Object
  },
  data() {
    return {
      selectedDocTypes: [],
      selectedEventTypes: [],
      docTypes: [],
      dateFormat: 'DD.MM.YYYY',
      eventTypes: [
        {
          type: 'admission',
          name: this.$i18n.t('documents.admission')
        },
        {
          type: 'transfer',
          name: this.$i18n.t('documents.transfer')
        },
        {
          type: 'period',
          name: this.$i18n.t('documents.period')
        },
        {
          type: 'settings',
          name: this.$i18n.t('documents.settings')
        },
      ],
      statuses: [
        {
          type: 'null',
          name: this.$i18n.t('documents.all')
        },
        {
          type: 'true',
          name: this.$i18n.t('documents.signed')
        },
        {
          type: 'false',
          name: this.$i18n.t('documents.not_signed')
        },
      ],
      owners: [
        {
          type: null,
          name: this.$i18n.t('documents.all')
        },
        {
          type: 'my',
          name: this.$i18n.t('documents.my_documents')
        },
        {
          type: 'add',
          name: this.$i18n.t('documents.i_am_responsible')
        },
      ],
      defaultDates: [
        date,
        this.addDays(date, 7)
      ],
      selectedDates: [
        date,
        this.addDays(date, 7)
      ],
      processedAt: [
        date,
        this.addDays(date, 7)
      ],
      isTodayDocument: false,
      selectedStatuses: 'null',
      selectedWhoseDoc: 'null',
      selectedDateLabels: {
        today: false,
        week: false,
        month: false,
        year: false
      },
      filter: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      isOpenFilterDesktop: true,
      isOpenFilterMobile: false,
      windowWidth: 0
    }
  },
  updated() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    documentsLength() {
      return this.documents.length
    },
  },
  methods: {
    showArchiveModal() {
      if (!this.documentsLength) {
        return
      }

      this.setIdDocuments([])
      $('#modalGetArchive').modal('show')
    },
    getFilters() {
      this.setShowDocumentsLoader(true)
      const response = api.getDocumentsFilters()

      response.then(res => {
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        }

        if (res.data.error && res.data.error === 403 && res.data.errorStr) {
          this.setShowDocumentsLoader(false)
          this.setRestrictedError(res.data.errorStr)
          return
        } else if (res.data.error && res.data.errorStr) {
          this.setShowDocumentsLoader(false);
          notification.error(res.data.errorStr)
          return
        }

        if (!res.data.result || !res.data.result.filters) {
          this.setShowDocumentsLoader(false)
          return
        }

        this.docTypes = res.data.result.filters.type
        this.selectedDates = [
          moment(res.data.result.filters.factRealDocDate.gte, this.dateFormat).toDate(),
          moment(res.data.result.filters.factRealDocDate.lte, this.dateFormat).toDate(),
        ]

        this.getDocumentsByFilter()
      }).catch(() => {
        this.setShowDocumentsLoader(false)
      })
    },
    getDocumentsByFilter() {
      this.buildFilter()
      this.getSignRules()
      this.getDocuments()
    },
    buildFilter() {
      const filter = {
        depId: [],
        fired: null,
        posId: [],
        subId: [],
        eventType: this.selectedEventTypes,
        type: this.selectedDocTypes,
        whose: this.selectedWhoseDoc,
      }

      if (this.selectedDates[0] && this.selectedDates[1]) {
        filter.factRealDocDate = {
          gte: this.selectedDates[0].getDate() + '.' + (+this.selectedDates[0].getMonth() + 1) + '.' + this.selectedDates[0].getFullYear(),
          lte: this.selectedDates[1].getDate() + '.' + (+this.selectedDates[1].getMonth() + 1) + '.' + this.selectedDates[1].getFullYear(),
        }
      } else {
        filter.factRealDocDate = {
          gte: '',
          lte: '',
        }
      }

      /*if (this.selected.length) { todo
        filter.id = this.selected
      }*/

      if (this.selectedStatuses !== 'null') {
        filter.processed = this.selectedStatuses === 'true'
      }

      if (filter.processed) {
        if (this.processedAt[0] && this.processedAt[1]) {
          filter.processedAt = {
            gte: this.processedAt[0].getDate() + '.' + (+this.processedAt[0].getMonth() + 1) + '.' + this.processedAt[0].getFullYear(),
            lte: this.processedAt[1].getDate() + '.' + (+this.processedAt[1].getMonth() + 1) + '.' + this.processedAt[1].getFullYear(),
          }
        } else {
          filter.processedAt = {
            gte: '',
            lte: '',
          }
        }
      }

      this.filter = filter
      this.setFilter(filter)
    },
    addDays(date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    },
    setToday() {
      this.selectedDates = [
        date,
        this.addDays(date, 0)
      ]
      this.clearSelectedDateLabels()
      this.selectedDateLabels.today = true
      this.getDocumentsByFilter()
      this.isTodayDocument = true
    },
    unsetDates() {
      this.selectedDates = []
      this.clearSelectedDateLabels()
      this.selectedDateLabels.today = true
      this.getDocumentsByFilter()
      this.isTodayDocument = false
      this.clearSelectedDateLabels()
    },
    setWeek() {
      this.selectedDates = [
        date,
        this.addDays(date, 7)
      ]
      this.clearSelectedDateLabels()
      this.selectedDateLabels.week = true
      this.getDocumentsByFilter()
      this.isTodayDocument = false
    },
    setMonth() {
      this.selectedDates = [
        date,
        this.addDays(date, 31)
      ]
      this.clearSelectedDateLabels()
      this.selectedDateLabels.month = true
      this.getDocumentsByFilter()
      this.isTodayDocument = false
    },
    setYear() {
      this.selectedDates = [
        date,
        this.addDays(date, 365)
      ]
      this.clearSelectedDateLabels()
      this.selectedDateLabels.year = true
      this.getDocumentsByFilter()
      this.isTodayDocument = false
    },
    clearSelectedDateLabels() {
      this.selectedDateLabels.today = false
      this.selectedDateLabels.week = false
      this.selectedDateLabels.month = false
      this.selectedDateLabels.year = false
    },
    selectedDatesChanged() {
      this.clearSelectedDateLabels()
      this.getDocumentsByFilter()
    },
    downloadExcel() {
      if (!this.documentsLength) {
        return;
      }

      this.resetSelected();
      this.buildFilter();
      const response = api.downloadDocs({ ...this.filter, disabled: false }, {
        sort: "type",
        format: "xlsx",
        hideBarcode: false,
        hideDates: false,
        hideLogo: false,
        showDepartment: false,
        showLogTitle: false,
        fromExternalEducation: true
      });

      response.then(res => {
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        }

        if (!res.data.error) {
          notification.information(this.$i18n.t('documents.gen_task_added'));
        }
      });
    },
    isDesktop() {
      const widthDesktop = 992
      return this.windowWidth > widthDesktop
    },
    filterToggle() {
      if (this.isDesktop()) {
        this.isOpenFilterDesktop = !this.isOpenFilterDesktop;
      } else {
        this.isOpenFilterMobile = !this.isOpenFilterMobile;
      }

      $('.documents__filter').slideToggle();
      $('.setting-icon ').toggle();
      $('.setting-select-icon ').toggle();
    },
    todayDocumentToggle() {
      if (this.isTodayDocument == true) {
        this.isTodayDocument = false
        this.selectedDates = this.defaultDates

        this.clearSelectedDateLabels()
        this.getDocumentsByFilter()
      } else {
        this.defaultDates = this.selectedDates;
        this.isTodayDocument = true
        this.setToday()
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      const filterDisplay = $('.documents__filter').css('display');
      const isNowOpenFilter = (filterDisplay == 'none') ? false : true;

      if (isNowOpenFilter) {
        $('.setting-icon').css('display', 'none');
        $('.setting-select-icon').css('display', 'flex');
      } else {
        $('.setting-icon').css('display', 'flex');
        $('.setting-select-icon').css('display', 'none');
      }

      if (
          (this.isOpenFilterDesktop != isNowOpenFilter && this.isDesktop()) ||
          (this.isOpenFilterMobile != isNowOpenFilter && !this.isDesktop())
      ) {
        $('.documents__filter').toggle();
      }
    },
    ...mapMutations([
      'setShowDocumentsLoader',
      'setIdDocuments'
    ])
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "CertificateBlock",
  props: {
    certificate: Object,
    document: Object,
    getFormattedDate: Function,
    isCertificateActive: Function,
  },
  computed: {
    ...mapGetters([
      'isDsExpiredStatus',
    ]),
    isPersonalCertificate() {
      return this.certificate.type === 'personal'
    },
    docDateNotNow() {
      const nowDate = moment().format("DD.MM.Y")
      const docDate = this.document.factDate == "" ? this.document.date : this.document.factDate;

      return nowDate !== docDate;
    }
  }
}

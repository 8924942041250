//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapActions, mapMutations} from "vuex"
import TestHeader from "./TestHeader"
import Description from "./Description"
import api from '../../api/api'
import TestResults from "./TestResults";
import Answer from "./answer/Answer";
import Question from "./question/Question";
import Loader from "../main/Loader";
import util from "../../utils"

export default {
  name: "CustomTest",
  components: {
    Question,
    Answer,
    TestResults,
    Description,
    TestHeader,
    Loader,
  },
  props: {
    test: Object,
    stopPing: Function,
    stopTest: Function,
    courseId: Number,
    sessionId: String,
  },
  created() {
    if (this.paperId) {
      this.getTrainingQuestions()
    } else {
      this.getTestQuestions()
    }
  },
  data() {
    return {
      questions: [],
      currentQuestionIndex: 0,
      isTestPassed: false,
      isTestRight: false,
      userAnswers: {},
      rightAnswers: {},
      rightAnswersCount: 0,
      showLoader: true,
    }
  },
  methods: {
    setAnswer(questionId, answers) {
      let userAnswers = this.userAnswers

      if (Array.isArray(answers) && userAnswers[questionId] && !answers.length) {
        delete (userAnswers[questionId])
      } else if (!answers && userAnswers[questionId]) {
        delete (userAnswers[questionId])
      } else {
        userAnswers[questionId] = answers
      }

      for (let question of this.questions) {
        if (question.ID == questionId) {
          question.ANSWERED = true
        }
      }


      this.userAnswers = Object.assign({}, userAnswers)
    },

    setCurrentQuestion(questionIndex, questionId, force = false) {
      if (!this.questions[questionIndex]) {
        return
      }

      if (this.isActive(questionIndex) || force) {
        this.currentQuestionIndex = questionIndex
      }
    },

    getTestQuestions() {
      this.showLoader = true

      this.getTest(this.testId).then((data) => {
        if (data.data && data.data.result && data.data.result.questions) {
          this.questions = data.data.result.questions
        }
      }).finally(() => {
        this.showLoader = false
      });
    },

    getTrainingQuestions() {
      this.showLoader = true

      this.getPaperQuestions({testId: this.testId, paperId: this.paperId}).then((data) => {
        if (data.data && data.data.result && data.data.result.questions) {
          this.questions = data.data.result.questions
        }

        if (data.data && data.data.result && data.data.result.test) {

          if ( data.data.result.test.DURATION) {
            this.setTestDuration(util.getCountdownTime(parseInt(data.data.result.test.DURATION)))
          }

          this.setTrainingTest({
            NAME: data.data.result.test.NAME,
            SUBTITLE: this.$t('training_list.header_title'),
            TYPE: 'training',
            INDEX: this.paperId
          })
        }
      }).finally(() => {
        this.showLoader = false
      });
    },

    goToNextQuestion() {
      if (this.isNotLastQuestion && this.userAnswers[this.currentQuestion.ID]) {
        if (this.questions[this.currentQuestionIndex + 1]) {
          this.setCurrentQuestion(this.currentQuestionIndex + 1, parseInt(this.questions[this.currentQuestionIndex + 1].ID))
        }
      }
    },

    goToPrevQuestion() {
      if (this.currentQuestionIndex) {
        if (this.questions[this.currentQuestionIndex - 1]) {
          this.setCurrentQuestion(this.currentQuestionIndex - 1, parseInt(this.questions[this.currentQuestionIndex - 1].ID))
        }
      }
    },

    saveResults() {
      if (this.userAnswers[this.currentQuestion.ID] === undefined || !this.isActiveSaveButton || this.isTestPassed) {
        return
      }

      const formData = new FormData();

      formData.append('TEST_ID', this.testId);
      if (this.token) formData.append('TOKEN', this.token);
      formData.append('SESSION_ID', this.sessionId);
      if(this.currentStudent && this.currentStudent.ID) formData.append('student_id', this.currentStudent.ID);
      formData.append('ANSWERS', JSON.stringify(this.userAnswers));

      this.showLoader = true
      this.stopTest()
      api.saveTestResults(formData).then((response) => {
        this.showLoader = false

        if (!response || !response.data || !response.data.result || !response.data.result.saved || !response.data.result.saved.ANSWERS) {
          return
        }

        this.rightAnswers = {}

        for (let answerId in response.data.result.saved.ANSWERS) {
          if (response.data.result.saved.ANSWERS[answerId].CORRECT) {
            this.rightAnswersCount++
          }

          for (let question of this.questions) {
            if (question.ID == answerId) {
              question.PASSED = response.data.result.saved.ANSWERS[answerId].CORRECT
              question.DESCRIPTION = response.data.result.saved.ANSWERS[answerId].DESCRIPTION
              question.RIGHT_ANSWERS = response.data.result.saved.ANSWERS[answerId].RIGHT_ANSWERS
            }
          }
        }

        this.isTestPassed = true
        this.isTestRight = response.data.result.saved.PASSED
        this.currentQuestionIndex = 0
      }).catch(() => {
        this.showLoader = false
      });
    },

    isActive(index) {
      return index == 0 ||
        (
          (this.questions[index - 1] && this.userAnswers[this.questions[index - 1]['ID']])
          && this.isActive(index - 1)
        )
    },
    ...mapActions([
      'getTest',
      'getPaperQuestions'
    ]),

    ...mapMutations([
        'setTestDuration',
        'setTrainingTest'
    ])
  },
  computed: {
    backUrl() {
      
      return this.paperId
      ? {name: 'trainings'}
      : (this.$route.params.type) 
        ? {name: 'course', params: { id: this.$route.params.needId, type: this.$route.params.type }} 
        : {name: 'course', params: { id: this.$route.params.needId }};
    },
    testId() {
      return this.$route.params.id
    },
    paperId() {
      return this.$route.params.paperId
    },
    currentQuestionNumber() {
      return this.currentQuestionIndex + 1
    },
    currentQuestion() {
      if (!this.questions || !this.questions[this.currentQuestionIndex]) {
        return {}
      }

      return this.questions[this.currentQuestionIndex]
    },

    currentQuestionName() {
      if (!this.currentQuestion || !this.currentQuestion.NAME) {
        return ''
      }

      return this.currentQuestion.NAME
    },

    isNotLastQuestion() {
      if (!this.questions || !this.currentQuestion) {
        return false
      }

      return this.questions.length - this.currentQuestionIndex > 1
    },

    isActiveSaveButton() {
      return this.userAnswers[this.currentQuestion.ID] && !this.isTestPassed
    },

    ...mapState([
      'token',
      'currentStudent',
    ]),
  }
}

//
//
//
//
//
//
//
//

export default {
  name: "MenuIcon",
  props: {
    click: Function
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Item from "@/components/training/Item";
export default {
  name: "Folder",
  components: {Item},
  props: {
    depth: {
      type: Number,
      default: 0
    },
    folder: Object,
    testId: Number
  },
  data() {
    return {
      itemType: 'item',
      folderType: 'folder',
      openedDropdown: false
    }
  },
  methods: {
    onClickHeader() {
      this.openedDropdown = !this.openedDropdown
    }
  },
  computed: {
    isFolder() {
      return this.folder && this.folder.TYPE === this.folderType
    },
    isPaper() {
      return this.folder && this.folder.TYPE === this.itemType
    },
    hasChildren() {
      return this.folder && this.folder.CHILDREN && Array.isArray(this.folder.CHILDREN)
    },
    hasPapers() {
      return this.hasChildren && this.folder.CHILDREN.some(item => {
        return item['TYPE'] === this.itemType
      })
    }
  }
}

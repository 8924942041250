//
//
//
//

export default {
  name: "TextLesson",
  props: {
    src: String
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '../../../app.config'
import DocMaterial from "../view-material/DocMaterial";
import { mapState, mapMutations } from 'vuex'
import ImageMaterial from "@/components/view-material/ImageMaterial";
import utils from '../../utils'
import PdfMaterial from "@/components/view-material/PdfMaterial";
import Loader from "@/components/main/Loader";

export default {
  name: "AgreeDocumentView",
  components: {Loader, PdfMaterial, ImageMaterial, DocMaterial},
  data() {
    return {
      config,
      prevRoute: null,
    }
  },
  methods: {
    downloadDoc() {
      if (this.docParamsToDownload) {
        utils.downloadDocument(this.docParamsToDownload, this.setShowDocumentsLoader)
      }
    },
    ...mapMutations([
        'setDocParamsToDownload',
        'setShowDocumentsLoader'
    ])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  destroyed() {
    this.setDocParamsToDownload(null)
  },
  computed: {
    docUrl() {
      const link = this.$router.currentRoute.params.link

      if (!link) {
        return  ''
      }

      if (this.isAgreeView) {
        return link
      }

      if (!this.isSimpleType
        && !this.isPersonnelDocType
        && !this.isPdfType
      ) {
        return config.siteUrl + link
      }

      return link
    },
    isDocType () {
      return this.type === 'doc'
    },
    isPersonnelDocType () {
      return this.type === 'personnel'
    },
    isImageType () {
      return this.type === 'image'
    },
    isSimpleType () {
      return this.type === 'simple'
    },
    isPdfType () {
      return this.type === 'pdf'
    },
    backLink() {
      if (this.$router.currentRoute.query && this.$router.currentRoute.query.type && this.$router.currentRoute.query.type === 'personnel') {
        return { name: 'personnel_documents'}
      }

      if (this.isAgreeView) {
        if (this.prevRoute && this.prevRoute.name === 'registrationVnot') {
          return { name: 'registrationVnot'}
        } else {
          return { name: 'login'}
        }
      }

      return { name: 'documents'}
    },
    type() {
      const type = this.$router.currentRoute.params.type

      if (!type) {
        return  'doc'
      }

      return type
    },
    ...mapState([
      'worker',
      'docParamsToDownload',
      'showDocumentsLoader'
    ]),
    isAgreeView() {
      return this.$router.currentRoute.name === 'agree-document'
    }
  }
}

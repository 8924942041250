//
//
//
//
//
//
//
//
//
//

import logo from "@/assets/img/trainings/logo.png";

export default {
  props: ['folder', 'img'],
  data() {
    return {
      url: this.img || logo,
      isOverflow: false,
      isShow: false,
    }
  },
  methods: {
    gotoFolder() {
      this.$router.push({name: 'subsection', params: { id: this.folder.ID, section: this.folder, children: this.folder.CHILDREN } });
    },
    handlePopover(show) {
      if (this.isOverflow) {
        this.isShow = show;
      }
    }
  },
  mounted() {
    if (this.$refs.folder.scrollWidth > this.$refs.folder.clientWidth)
      this.isOverflow = true;
  },
  beforeUpdate() {
    if (!this.isOverflow && this.$refs.folder.scrollWidth > this.$refs.folder.clientWidth)
      this.isOverflow = true;
  }
}

//
//
//
//
//
//
//

export default {
  name: "SidebarSignIcon"
}

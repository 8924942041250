//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {getCertificateKepNames, createSign} from "@/sign/sign";
import {mapState} from "vuex";
import moment from "moment";
import CertificateBlock from "@/components/documents/modals/CertificateBlock";
import CertificateItems from "@/components/documents/modals/CertificateItems";
import api from "@/api/api";
import notification from "@/notification/notification";
import store from "../../../store";
import {getSignInfo, getSignParams} from "@/sign/operations";
import SendToRvrBtn from "@/components/documents/btns/SendToRvrBtn";
import OrgSignInfo from "@/components/documents/modals/items/OrgSignInfo";
import AlertInfoIcon from "@/components/icons/AlertInfoIcon";
import ErrorIcon from "@/components/icons/ErrorIcon";
import ConfirmSignIcon from "@/components/icons/ConfirmSignIcon";

export default {
  name: "RvrModal",
  components: {ConfirmSignIcon, ErrorIcon, CertificateItems, CertificateBlock, SendToRvrBtn, OrgSignInfo, AlertInfoIcon},
  props: {
    hide: Function,
    document: Object,
    documentType: String,
    getDocuments: Function,
  },
  methods: {
    backdropClick(e) {
      if (e.target.id && e.target.id === this.modalId) {
        this.hide()
      }
    },
    getCertificates() {
      if (this.document.allowSendToTrudVsem && !this.document.signedByOrgDs) {
        getCertificateKepNames().then((certs) => {
          this.certificatesKep = certs
        }).catch((e) => {
          if (e === 'plugin_not_found') {
            this.isPluginError = true
          } else {
            this.isGetCertificatesError = true
          }
        })
      }
    },
    setSelectedCertificate(certificate) {
      this.selectedCertificate = certificate
    },
    getFormattedDate(date, format = this.certificateDateFormat) {
      return moment(date).format(format)
    },
    sendSign(sign = null) {
      const signParams = getSignParams(this.document.id, this.documentType, this.selectedCertificate, true, sign)

      this.isLoader = true

      api.sign(signParams).then((result) => {
        if (result.data && result.data.result) {
          notification.success(this.$i18n.t('documents.signed_succes'))
          this.hide()
          this.getDocuments()
        }
      }).catch((e) => {
        console.log(e);
        notification.error(this.$i18n.t('certificates_modal.send_error'))
      }).finally(() => {
        this.isLoader = false
      })
    },
    sendTrudVsem() {
      const params = {
        docId: this.document.id,
        student_id: store.state.currentStudent.ID
      }

      this.isLoader = true

      api.sendDocToTrudVsem(params).then((result) => {
        if (result.data && result.data.result && result.data.result.success) {
          notification.success("Отправлено на Рвр")
          this.hide()
          this.getDocuments()
        }
      }).catch((e) => {
        console.log(e);
        notification.error(this.$i18n.t('Не удалось отправить'))
      }).finally(() => {
        this.isLoader = false
      })
    },
    isPersonalCertificate() {
      return this.selectedCertificate && this.selectedCertificate.type === 'personal'
    },
    getFileContent(file) {
      var header = ";base64,";
      var fileData = file;
      return fileData.substr(fileData.indexOf(header) + header.length);
    },
    getOrgSigns() {
      let value = []

      for (const signer in this.document.signers) {
        const signerInfo = this.document.signers[signer]
        if (typeof signerInfo.isOrgDs !== 'undefined' && signerInfo.isOrgDs === true) {
          value.push(getSignInfo(signerInfo, this))
        }
      }

      return value
    },
    async signAndSendDocument() {
      if (!this.selectedCertificate || !this.isSelectedActive || this.isLoader) {
        return
      }

      this.isLoader = true

      const downloadResponse = await api.downloadDoc(this.document.id, this.documentType, true)

      if (downloadResponse && downloadResponse.data && downloadResponse.data.result && downloadResponse.data.result.src) {
        let url = new URL(downloadResponse.data.result.src)
        url.searchParams.set('sign', 0)

        const docUrl = url.toString()

        try {
          fetch(docUrl)
              .then(res => res.ok ? res.blob() : Promise.reject(res))
              .then(file => {
                if (!file) {
                  notification.error(this.$i18n.t('certificates_modal.file_error'))
                  this.isLoader = false
                  return
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  const fileContent = this.getFileContent(reader.result)

                  this.createAndSendSign(fileContent)
                };
              })
              .catch(() => {
                this.isLoader = false
                notification.error(this.$i18n.t('certificates_modal.file_error'))
              });
        } catch (e) {
          this.isLoader = false
          console.log(e);
          notification.error(this.$i18n.t('certificates_modal.file_error'))
        }

      }
    },
    async createAndSendSign(fileContent) {
      createSign(this.selectedCertificate.SerialNumber, fileContent).then((sign) => {
        this.sendSign(sign)
      }).catch((e) => {
        console.log(e);
        notification.error(this.$i18n.t('certificates_modal.sign_error'))
      }).finally(() => {
        this.isLoader = false
      })
    },
  },
  data() {
    return {
      modalId: 'modalRvr',
      certificatesKep: [],
      isPluginError: false,
      pluginError: this.$t('documents.pluginError'),
      isGetCertificatesError: false,
      selectedCertificate: null,
      isLoader: false,
    }
  },
  computed: {
    ...mapState([
      'worker',
      'currentStudent'
    ]),
    isStatusWarning() {
      if (this.document.signedByOrgDs) {
        return false;
      }
      return true
    },
    isStatusError() {
      if (!this.document.allowSendToTrudVsem && this.document.allowSendToTrudVsemError.length > 0) {
        return true;
      }
      return false;
    },
    isSelectedActive() {
      if (!this.selectedCertificate || !this.selectedCertificate.ValidToDate) {
        return false
      }

      const certificateValidToDate = moment(this.selectedCertificate.ValidToDate).toDate()

      if (!(certificateValidToDate instanceof Date)) {
        return false
      }

      const date = new Date()

      return (certificateValidToDate >= date)
    },
  },
  mounted() {
    this.getCertificates()
  },
}

//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/main/Loader";

export default {
  name: "SendToRvrBtn",
  components: {Loader},
  props: {
    action: {type: Function},
    text: {type: String},
    btnClass: {type: String},
    isLoader: {type: Boolean}
  }
}

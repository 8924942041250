//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import config from '../../../app.config'
import Loader from "../main/Loader";
import FormField from "@/components/auth/FormField";
import Email from "@/components/auth/fields/Email";
import Phone from "@/components/auth/fields/Phone";
import AgreeCheckbox from "@/components/main/AgreeCheckbox";
import api from "@/api/api";
import notification from "@/notification/notification";

export default {
  name: "RegistrationVnot",
  components: {Phone, Loader, FormField, Email, AgreeCheckbox},
  metaInfo() {
    return {
      title: this.title,
    }
  },
  created() {
    if (this.token) {
      this.logout()
    }
  },
  data: function () {
    return {
      fio: '',
      email: '',
      phone: '',
      error: '',
      emailError: '',
      fioError: '',
      config,
      showLoader: false,
      title: this.$i18n.t('auth.title'),
      id: '',
      needCheckCode: false,
      timeOut: 0,
      message: '',
      isAgree: false,
      linkIsSend: false,
    }
  },
  methods: {
    fioValidator(value) {
      let arValue = value.trim().split(/\s+/)

      return arValue.length === 2 || arValue.length === 3
    },
    setEmailError(value) {
      this.emailError = value
    },
    setShowLoader(value) {
      this.showLoader = Boolean(value)
    },
    setPhone(value) {
      this.phone = value
    },
    setEmail(value) {
      this.email = value
    },
    setNeedCheckCode(value) {
      this.needCheckCode = value
    },
    setIsAgree(value) {
      this.isAgree = Boolean(value)
    },
    setError(error = '') {
      this.error = error
    },
    setMessage(message = '') {
      this.message = message
    },
    cleanErrors() {
      this.error = ''
    },
    setFio(value) {
      this.fio = value
    },
    setFioError(value) {
      this.fioError = value
    },
    checkResponse(response, isCode) {
      if (response.data && response.data.error) {
        response.data.errorStr ? this.setError(response.data.errorStr) : this.setError(this.$t('errors.undefined_error'))
        if (!isCode) {
          this.needCheckCode = false
        }

        this.message = ''
      }

      if (response.data && response.data.result && response.data.result.need_confirmation) {
        this.needCheckCode = true
        this.message = this.$t('login.need_code') + ' ' + this.phone
        this.cleanErrors()

        if (response.data.result.timeout) {
          this.timeOut = response.data.result.timeout * 1000
        }
      }
    },
    ...mapActions([
      'login',
      'getUserAndStudents',
      'getStudentInfo',
      'logout',
    ]),
    ...mapMutations([
      'setToken',
      'setAuthMethod'
    ]),
    send(e) {
      this.showLoader = true
      e.preventDefault()

      const regRoute = this.$router.resolve({
        name: 'registrationVnotConfirm'
      })

      const data = {
        fio: this.fio,
        email: this.email,
        phone: this.phone,
        registrationLink: regRoute.href
      }

      const response = api.vnotRegistrations(data);

      response.then(data => {
        if (data.data && data.data.error && data.data.errorStr) {
          return
        }

        if (data.data && data.data.result && (data.data.result.result === true)) {
          this.linkIsSend = true;
        }
      }).catch(() => {
        notification.error(this.$t('errors.undefined_error'))
      }).finally(() => {
        this.showLoader = false
      })
    },
  },
  computed: {
    docUrl() {
      if (!process || !process.env || !process.env.VUE_APP_AGREE_DOCS_URL) {
        return ''
      }
      return process.env.VUE_APP_AGREE_DOCS_URL
    },
    ...mapState([
      'token',
      'authMethod',
      'idAuthMethod',
      'phoneAuthMethod',
    ]),
    ...mapGetters([
      'isIdAuthMethod',
      'isPhoneAuthMethod',
    ]),
    buttons() {
      return [
        {
          label: this.$t('registration.login_button'),
          click: async (e) => {
            e.preventDefault()
            this.setShowLoader(true)
            this.send(e)
          },
          disabled: !this.isAgree || this.fio === '' || this.email === '' || this.phone === '' || this.emailError,
        }
      ]
    },
    buttonsForState() {
      return this.buttons
    },
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import 'vue-file-agent/dist/vue-file-agent.css';

import FileDeleteIcon from "@/components/icons/FileDeleteIcon";
export default {
  name: "TheFileInput",
  components: {FileDeleteIcon},
  props: {
    //10MB
    maxSize: String,
    //10
    maxFilesCount: Number,
    fileRecords: Array,
    setFileRecords: Function
  },
  watch: {
    files() {
      this.setFileRecords(this.files)
    }
  },
  data: function () {
    return {
      files: this.fileRecords
    };
  },
  methods: {
    onBeforeDelete: function (fileRecord) {
      const id = this.files.indexOf(fileRecord);
      this.files.splice(id, 1);
    },
  },
}

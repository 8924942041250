//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from 'vue2-datepicker';
import $ from "jquery";
import { mapMutations } from 'vuex';

import GetArchiveModal from "@/components/documents/modals/GetArchiveModal";

const date = new Date()

export default {
  name: "ExternalDocumentsFilters",
  components: { DatePicker, GetArchiveModal },
  props: {
    documentsCount: Number,
    selectedDatesInit: Array,
  },
  data() {
    return {
      date: '',
      selectedStatuses: 'null',
      statusOptions: [
        {
          value: 'null',
          text: this.$i18n.t('documents.all')
        },
        {
          value: 'true',
          text: this.$i18n.t('documents.signed')
        },
        {
          value: 'false',
          text: this.$i18n.t('documents.not_signed')
        },
      ],
      selectedDateLabels: {
        today: false,
        week: false,
        month: false,
        year: false
      },
      selectedDates: this.$props.selectedDatesInit,
      processedAt: [
        date,
        this.addDays(date, 7)
      ],
      filter: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    }
  },
  methods: {
    getDocumentsByFilter() {
      this.buildFilter();
    },
    buildFilter() {
      const filter = {};

      if (this.selectedDates[0] && this.selectedDates[1]) {
        filter.factRealDocDate = {
          gte: this.selectedDates[0].getDate() + '.' + (+this.selectedDates[0].getMonth() + 1) + '.' + this.selectedDates[0].getFullYear(),
          lte: this.selectedDates[1].getDate() + '.' + (+this.selectedDates[1].getMonth() + 1) + '.' + this.selectedDates[1].getFullYear(),
        }
      } else {
        filter.factRealDocDate = {
          gte: '',
          lte: '',
        }
      }

      if (this.selectedStatuses !== 'null') {
        filter.processed = this.selectedStatuses === 'true';
      }

      if (filter.processed) {
        if (this.processedAt[0] && this.processedAt[1]) {
          filter.processedAt = {
            gte: this.processedAt[0].getDate() + '.' + (+this.processedAt[0].getMonth() + 1) + '.' + this.processedAt[0].getFullYear(),
            lte: this.processedAt[1].getDate() + '.' + (+this.processedAt[1].getMonth() + 1) + '.' + this.processedAt[1].getFullYear(),
          }
        } else {
          filter.processedAt = {
            gte: '',
            lte: '',
          }
        }
      }

      this.filter = filter;
      this.$emit('setFilter', filter);
    },
    addDays(date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    },
    setToday() {
      this.selectedDates = [
        date,
        this.addDays(date, 0)
      ];
      this.clearSelectedDateLabels();
      this.selectedDateLabels.today = true;
      this.getDocumentsByFilter();
      this.isTodayDocument = true;
    },
    setWeek() {
      this.selectedDates = [
        date,
        this.addDays(date, 7)
      ];
      this.clearSelectedDateLabels();
      this.selectedDateLabels.week = true;
      this.getDocumentsByFilter();
      this.isTodayDocument = false;
    },
    setMonth() {
      this.selectedDates = [
        date,
        this.addDays(date, 31)
      ];
      this.clearSelectedDateLabels();
      this.selectedDateLabels.month = true;
      this.getDocumentsByFilter();
      this.isTodayDocument = false;
    },
    setYear() {
      this.selectedDates = [
        date,
        this.addDays(date, 365)
      ];
      this.clearSelectedDateLabels();
      this.selectedDateLabels.year = true;
      this.getDocumentsByFilter();
      this.isTodayDocument = false;
    },
    clearSelectedDateLabels() {
      this.selectedDateLabels.today = false;
      this.selectedDateLabels.week = false;
      this.selectedDateLabels.month = false;
      this.selectedDateLabels.year = false;
    },
    selectedDatesChanged() {
      this.clearSelectedDateLabels();
      this.getDocumentsByFilter();
    },
    showArchiveModal() {
      if (!this.documentsCount) {
        return;
      }

      this.setIdDocuments([]);
      $('#modalGetArchive').modal('show');
    },
    ...mapMutations(['setIdDocuments']),
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import YoutubeQuestion from "./YoutubeQuestion"
import ImageQuestion from "./ImageQuestion"
import TextQuestion from "./TextQuestion"
import VideoQuestion from "./VideoQuestion"
import AudioQuestion from "./AudioQuestion"

export default {
  name: "Question",

  props: {
    currentQuestion: Object,
    currentQuestionIndex: Number
  },
  data() {
    return {
      textType: 'text',
      audioType: 'audio',
      videoType: 'video',
      imageType: 'image',
      youtubeType: 'youtube',
    }
  },

  computed: {
    isTextQuestionType() {
      if (!this.currentQuestion || !this.currentQuestion.CONTENT_TYPE) {
        return false
      }

      return this.currentQuestion.CONTENT_TYPE === this.textType
    },

    isAudioQuestionType() {
      if (!this.currentQuestion || !this.currentQuestion.CONTENT_TYPE) {
        return false
      }

      return this.currentQuestion.CONTENT_TYPE === this.audioType
    },

    isVideoQuestionType() {
      if (!this.currentQuestion || !this.currentQuestion.CONTENT_TYPE) {
        return false
      }

      return this.currentQuestion.CONTENT_TYPE === this.videoType
    },

    isYoutubeQuestionType() {
      if (!this.currentQuestion || !this.currentQuestion.CONTENT_TYPE) {
        return false
      }

      return this.currentQuestion.CONTENT_TYPE === this.youtubeType
    },

    isImageQuestionType() {
      if (!this.currentQuestion || !this.currentQuestion.CONTENT_TYPE) {
        return false
      }

      return this.currentQuestion.CONTENT_TYPE === this.imageType
    },
    url() {
      if (!process || !process.env || !process.env.VUE_APP_SITE_URL) {
        return ''
      }

      return process.env.VUE_APP_SITE_URL
    },
    questionindex () {
      return this.currentQuestionIndex + 1
    }
  },
  components: {
    YoutubeQuestion,
    ImageQuestion,
    TextQuestion,
    VideoQuestion,
    AudioQuestion,
  },
}

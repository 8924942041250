import router from "@/router";
import {CERTIFICATE_EXPIRED_STATUS} from "@/sign/consts";

const CERTIFICATE_APPROVED_STATUS = 'approved'
const CERTIFICATE_DECLINED_STATUS = 'rejected'
const CERTIFICATE_WAIT_STATUS = 'consideration'

export default {
  /**
   *
   * @param state
   * @returns {{}|any|IWorkerInfo|{globals: *}|Worker}
   */
  getCard: state => {
    return state.worker;
  },
  isIdAuthMethod: state => {
    return state.authMethod === state.idAuthMethod
  },

  isPhoneAuthMethod: state => {
    return state.authMethod === state.phoneAuthMethod
  },

  isContent: state => {
    return (state.authMethod === state.phoneAuthMethod)
      || (state.authMethod === state.idAuthMethod)
      || router.currentRoute.name === 'agree-document'
  },
  certificateStatus: state => {
    return (state.personalCertificate && state.personalCertificate.UF_STATUS && state.personalCertificate.UF_STATUS.code)
      ? state.personalCertificate.UF_STATUS.code
      : null
  },
  requestStatus: state => {
    return (state.dsRequest && state.dsRequest.UF_STATUS && state.dsRequest.UF_STATUS.code)
        ? state.dsRequest.UF_STATUS.code
        : null
  },
  isDsApprovedStatus: (state, getters) => {
    return getters.certificateStatus === CERTIFICATE_APPROVED_STATUS
  },
  isDsRequestWaitStatus: (state, getters) => {
    return getters.requestStatus === CERTIFICATE_WAIT_STATUS
  },
  isDsRequestRejectedStatus: (state, getters) => {
    return getters.requestStatus === CERTIFICATE_DECLINED_STATUS
  },
  isDsRequestApprovedStatus: (state, getters) => {
    return getters.requestStatus === CERTIFICATE_APPROVED_STATUS
  },
  isApprovedDsExist: (state, getters) => {
    return state.personalCertificate && getters.certificateStatus === CERTIFICATE_APPROVED_STATUS
  },
  isInWaitDsRequestExist: (state, getters) => {
    return state.dsRequest && getters.requestStatus === CERTIFICATE_WAIT_STATUS
  },
  isDsExpiredStatus: (state, getters) => {
    return getters.certificateStatus === CERTIFICATE_EXPIRED_STATUS
  },
  getIdDocuments: state => {
    return state.idDocuments
  },
  isAllowDsRequest: (state, getters) => {
    if (getters.isApprovedDsExist) {
      return false
    }

    return !getters.isInWaitDsRequestExist;
  },
  trialTests(state) {
    return state.trialTests;
  },
  normalisedSearch(state) {
    return state.searchTraining.toLowerCase();
  },
  getPreviousRouteName(state) {
    return state.previousRoute.name.replace('/');
  },
  getExternalDocuments(state) {
    return state.externalDocuments.documents || [];
  }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RadioAnswer from ".././answer/RadioAnswer"
import TextAnswer from ".././answer/TextAnswer"
import CouplesAnswer from ".././answer/CouplesAnswer"
import MultipleAnswer from ".././answer/MultipleAnswer"
import OrderAnswer from ".././answer/OrderAnswer"

export default {
  name: "Answer",
  props: {
    currentQuestion: Object,
    setAnswer: Function,
    userAnswers: Object,
    rightAnswers: Object,
    isTestPassed: Boolean,
  },
  computed: {
    isSingleAnswer() {
      return this.currentQuestion.TYPE === 'single'
    },

    isMultipleAnswer() {
      return this.currentQuestion.TYPE === 'multiple'
    },

    isTextAnswer() {
      return this.currentQuestion.TYPE === 'text'
    },

    isOrderAnswer() {
      return this.currentQuestion.TYPE === 'order'
    },

    isCouplesAnswer() {
      return this.currentQuestion.TYPE === 'couples'
    },
  },
  components: {
    OrderAnswer,
    MultipleAnswer,
    CouplesAnswer,
    TextAnswer,
    RadioAnswer,
  },
}

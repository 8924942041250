//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";
import notification from "@/notification/notification";
import api from "@/api/api";
import moment from "moment";
import {mapMutations} from "vuex";
import DatePicker from 'vue2-datepicker'
import Loader from "@/components/main/Loader";

export default {
  name: "PayrollModal",
  props: {
    setShowPreviewLoader: Function
  },
  components: {Loader, DatePicker},
  data() {
    return {
      selectedPersonnelDate: '',
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      filterPayroll: {
        dateBegin: null,
        dateEnd: null,
      },
      isLoader: false
    }
  },
  methods: {
    hidePayrollModal() {
      $('#modalPayroll').modal('hide')
    },
    buildPayrollFilter() {
      this.filterPayroll = {
        dateBegin: moment(this.selectedPersonnelDate).format('YYYYMMDD'),
      }
    },
    getPayroll() {
      this.isLoader = true
      this.buildPayrollFilter()

      if (!this.selectedPersonnelDate) {
        notification.error(this.$i18n.t('documents.not_dates'))
        this.isLoader = false
        return
      }

      const response = api.getPayroll(this.filterPayroll)

      this.setDocParamsToDownload(this.filterPayroll)

      response.then(res => {
        this.hidePayrollModal()

        if (res.data && res.data.result && res.data.result.previewLink) {
          this.$router.push({
            name: 'document-view',
            params: {
              link: decodeURIComponent(res.data.result.previewLink),
              type: res.data.result.type || 'personnel'
            },
            query: {
              type: 'personnel'
            }
          })
        }
      }).finally(() => {
        this.isLoader = false
      })
    },
    ...mapMutations([
      'setDocParamsToDownload'
    ])
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: "CertificateItems",
  props: {
    certificates: Array,
    selectedCertificate: Object,
    setSelectedCertificate: Function,
    getError: String,
    getFormattedDate: Function,
    canBeSelected: Function,
    isActive: Function,
    signPep: Function,
    allowPep: Boolean,
  },
  data() {
    return {
      isLoader: false,
      tooltipVisible: false,
      activeDateTooltipVisible: false
    }
  },
  methods: {
    async setCertificate(certificate) {
      if(!this.canBeSelected(certificate) || !this.isActive(certificate)) {
        return;
      }
      if (!this.isSelected(certificate)) {
        this.setSelectedCertificate(certificate)
      } else {
        this.setSelectedCertificate(null)
      }
    },
    isSelected(certificate) {
      return this.selectedCertificate && (certificate.SerialNumber === this.selectedCertificate.SerialNumber)
    },
    isPersonalCertificate(certificate) {
      return certificate.type === 'personal'
    },
    getCertTypeName(certificate) {
      return this.isPersonalCertificate(certificate) ? this.$t('certificates_modal.nep_type') : this.$t('certificates_modal.kep_type')
    }
  },
  computed: {
    ...mapState(['currentStudent', 'worker']),
  }
}

//
//
//
//
//
//
//
//

export default {
  name: "AlertInfoIcon"
}

//
//
//
//
//
//
//

import {mapState} from "vuex";
export default {
  name: "DescriptionSign",
  props: {
    document: Object
  },
  data() {
    return {
      showSignModal: false,
    }
  },
  computed: {
    currentSigner() {
      const current = this.document.signers.find(signer => signer.workerId === this.currentStudent.ID)

      return current || ''
    },

    calculateSignPercent() {
      if (this.document.dsSignersCount > 0) {
        return Math.floor(this.document.dsProcessedByCount / this.document.dsSignersCount * 100);
      }
      return 0;
    },

    isZeroPercent() {
      if (this.calculateSignPercent == 0) {
        return true
      }

      return false
    },

    isHundredPercent() {
      if (this.calculateSignPercent == 100) {
        return true
      }

      return false
    },

    dateText() {
      let signPercent = this.calculateSignPercent;

      return `${this.$t('sign.signers')} ${signPercent}%`
    },

    isSignedOnPaper() {
      return this.document.processed && !this.document.dsProcessed
    },
    ...mapState([
      'currentStudent',
      'hasAccessToSign'
    ])
  }
}

//
//
//
//
//
//
//
//
//
//

import TheDocumentContent from "@/components/documents/TheDocumentContent";

export default {
name: "ThePersonnelDocument",
  components: {TheDocumentContent},
  data() {
    return {
      type: 'persDoc'
    }
  },
}

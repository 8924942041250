//
//
//
//
//
//
//
//

import config from "/app.config";
  export default {
    name: "NoImage",
    data() {
      return {
        config
      }
    },
    props: {
      isRestricted: Boolean
    }
  }

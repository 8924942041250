//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "RestrictedMessage",
  props: {
    infoText: String,
  }
}

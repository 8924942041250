//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";
import SignerItem from "@/components/documents/modals/SignerItem";
import utils from "@/utils";
import {getSignInfo} from "@/sign/operations";

export default {
  name: "SignModal",
  components: {SignerItem},
  props: {
    hide: Function,
    download: Function,
    signers: Array,
  },
  data() {
    return {
      modalId: 'modalSign'
    }
  },
  methods: {
    backdropClick(e) {
      if (e.target.id && e.target.id === this.modalId) {
        this.hide()
      }
    },

    getSignerValues(signer) {
      if (signer.basic === undefined) {
        return  {}
      }

      let select = ['signer', 'state', 'signedAt', 'companyName', 'position'];

      if (typeof signer.sign !== "undefined" && signer.sign.isRvrSimple == true) {
        select.push('snils', 'esiaId');
      } else {
        select.push('inn', 'certId');
      }

      return getSignInfo(signer, this, select)
    },
    currentSignerValues() {
      return this.getSignerValues(this.currentSigner)
    },
  },
  computed: {
    currentSigner() {
      const current = this.signers.find(signer => signer.workerId === this.currentStudent.ID)

      return current || ''
    },

    otherSigners() {
      return this.signers.filter(signer => signer.workerId !== this.currentStudent.ID)
    },

    otherSignersChunked() {
      return utils.chunkArray(this.otherSigners, 2)
    },

    ...mapState([
      'currentStudent'
    ])
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'lodash';
import api from '@/api/api';

import Input from '@/components/core/Input';
import Loader from '@/components/main/Loader';
import Question from './Question.vue';
import TestItem from "./TestItem";

export default {
  components: { TestItem, Input, Question, Loader },
  data() {
    return {
      tests: this.$store.state.trialTests.CHILDREN.find(test => test.ID === this.$route.params.id)
      .CHILDREN.find(test => test.ID === this.$route.params.subsectionid)
      .CHILDREN.find(test => test.ID === this.$route.params.testid).CHILDREN,
      search: '',
      found: [],
      questions: [],
      shownCollapse: [],
      normalisedSearch: '',
      isQuestionsLoading: false,
    }
  },
  methods: {
    handleUpdateInput(event) {
      this.normalisedSearch = event.toLowerCase();
      this.search = event;
      if(event.length >= 3) {
        this.isQuestionsLoading = true;
        this.handleInput();
      } else if (this.questions.length) {
        this.questions = [];
      }
    },
    handleInput: debounce(function () {
      this.getQuestions();
    }, 500),
    async getQuestions() {
      try {
        const response = (await api.getPaperQuestions(this.$route.params.testid, null, {search: this.normalisedSearch })).data;
        if (response && response.result.questions && response.result.questions.length) {
          this.questions = response.result.questions;
        } else {
          this.questions = [];
        }
      } catch(e) {
        console.error(e);
        this.questions = [];
      } finally {
        this.isQuestionsLoading = false;
      }
    },
    handleClear() {
      this.search = '';
      this.normalisedSearch = '';
      this.questions = [];
    }
  },
}

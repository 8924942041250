//
//
//
//
//
//

export default {
  name: "Loader",
  props: {
    isLogin: Boolean
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapState} from 'vuex'
import Folder from "@/components/training/Folder";
import NavLinks from "@/components/main/NavLinks";
import Loader from "@/components/main/Loader";
import notification from "@/notification/notification";
import RestrictedMessage from "@/components/errorBlocks/RestrictedMessage";

export default {
  components: {RestrictedMessage, Loader, NavLinks, Folder},
  props: {},
  data() {
    return {
      tests: [],
      isLoader: true,
      restrictedError: ''
    }
  },
  name: 'Training',

  mounted() {
    this.getTests()
  },
  computed: {
    ...mapState([
        'currentStudent'
    ])
  },
  watch: {
    currentStudent() {
      this.getTests()
    }
  },
  methods: {
    getTests() {
      this.isLoader = true
      this.restrictedError = ''

      const response = this.getTrialTests()

      response.then(data => {
        if (data.data.error && data.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        }

        if (data.data.error && data.data.error === 403 && data.data.errorStr) {
          this.restrictedError = data.data.errorStr
        } else if (data.data.error && data.data.error && data.data.errorStr) {
          notification.error(data.data.errorStr)
        }

        if (!data || !data.data || !data.data.result || !data.data.result.testsTree) {
          return
        }

        this.tests = data.data.result.testsTree
      }).finally(() => {
        this.isLoader = false
      })
    },
    ...mapActions([
      'getTrialTests'
    ])
  }
};

//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
name: "SignIcon"
}

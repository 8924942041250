//
//
//
//
//
//

export default {
name: "ScanRequestIcon"
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/api/api";
import PinCode from "@/components/auth/fields/Pincode";
import notification from "@/notification/notification";

export default {
  name: "SignPepModal",
  props:{
    docId: String,
    close: Function
  },
  components: { PinCode },
  data() {
    return {
      pin: '',
      error: '',
      errorText: this.$t('sign.once_more'),
      signed: false,
      modalId: 'pepmodal',
      pending: false,
    }
  },
  destroyed() {
    if (this.signed) this.close(true);
  },
  methods: {
    async signPEP() {
      let studentId;
      if (this.pending) return;
      if (!(this.$store.state.currentStudent && this.$store.state.currentStudent.ID) && this.$store.state.worker && this.$store.state.worker.id)
        studentId = this.$store.state.worker.id;
      try {
        this.pending = true;
        const response = await api.signPep({ docId: this.docId, pin: Number(this.pin), ...(studentId && { 'student_id': studentId}) });
        if (response.data.error) {
          this.error = response.data.errorStr;
        } else {
          this.signed = true;
          notification.information(this.$t('sign.success'));
          this.close(true);
        }
      } catch(e) {
        console.error(e);
      } finally {
        this.pending = false;
      }
    },
    handleSetValue(value) {
      this.pin = value;
      this.cleanErrors();
    },
    getPasswordError() {
      return this.error;
    },
    setPasswordError() {
    },
    cleanErrors() {
      this.error = ''
    },
    backdropClick(e) {
      if (e.target.id && e.target.id === this.modalId) {
        this.close(false);
      }
    },
  }
}

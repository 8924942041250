//
//
//
//
//
//
//
//

export default {
  name: "SettingSelectIcon"
}

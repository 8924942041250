//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "Phone",
  props: {
    error: Boolean,
    title: String,
    placeholder: String,
    value: String,
    setValue: Function,
    cleanErrors: Function,
  },
  methods: {
    onInput: function (value) {
      const prevValue = this.value.replace(/[- ]+/g, '')
      const newValue = value.replace(/[- ]+/g, '')

      if ((newValue.length - prevValue.length === 2)) {
        value = value.slice(0, 4) + value.slice(5, value.length)
      } else if (newValue.length === 12 &&
          (newValue.slice(0, 2) + newValue.slice(4, 8) === prevValue.slice(0, 6))
      ) {
        value = value.slice(0, 4) + value.slice(5, value.length) + prevValue.substr(prevValue.length - 1)
      }

      this.setValue(value)
    },
    onInputClick() {
      this.cleanErrors()
      // this.setValueAndSelectionStart(arg)
    },
    setValueAndSelectionStart(arg) {
      if (!arg.target.value) {
        this.setValue('')
        this.setValue('7')
      } else if (arg.target.value.trim() === '+7' || arg.target.value.trim() === '7'){
        arg.target.selectionStart = arg.target.value.length;
      }
    },
    onFocusOut(arg) {
      if (arg.target.value.trim() === '+7' || arg.target.value.trim() === '7') {
        this.setValue('')
      }
    },
  }
}

//
//
//
//
//
//

export default {
  name: "ConfirmSignIcon"
}

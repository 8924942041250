//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoImage from "../main/NoImage";
import config from "../../../app.config";

export default {
  name: "Course",
  props: {
    course: Object
  },
  data: function () {
    return {
      config
    }
  },
  computed: {
    isRestricted() {
      return false // todo
    }
  },
  components: {NoImage}
}

//
//
//
//
//
//
//
//

export default {
  name: "TestResults",
  props: {
    isPassed: Boolean,
    count: Number,
    rightCount: Number
  },
  computed: {
    title() {
      return this.isPassed ? this.$i18n.t('custom_test.passed') : this.$i18n.t('custom_test.not_passed')
    },
    className() {
      return this.isPassed ? 'result-success' : 'result-danger'
    }
  }
}

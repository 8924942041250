//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";
import config from "../../../app.config";


export default {
  name: "TabLinks",
  props: {
    route: String,
    show: Boolean,
  },
  data() {
    return {
      itemClass: 'stand-window__nav-item',
      activeItemClass: 'stand-window__nav-item--active',
      config,

    }
  },
  computed: {
    links() {
      return [
        {
          link: 'login',
          label: this.$t('auth.login'),
          className: this.itemClass + ' ' + (this.isLogin ? this.activeItemClass : ''),
          active: this.isLogin,
        },
        {
          link: 'registration',
          label: this.$t('auth.registration'),
          className: this.itemClass + ' ' + (this.isRegistration ? this.activeItemClass : ''),
          active: (this.isRegistration) && this.isPhoneAuthMethod,
        },
        {
          link: 'forgot-password',
          label: this.$t('auth.forgot_password'),
          className: this.itemClass + ' ' + (this.isForgotPassword ? this.activeItemClass : ''),
          active: this.isForgotPassword
        }
      ]
    },
    isIdAuthMethod() {
      return this.authMethod === this.idAuthMethod
    },

    isPhoneAuthMethod() {
      return this.authMethod === this.phoneAuthMethod
    },
    activeLinks() {
      return this.links.filter(link => {
        return link.active
      })
    },
    isLogin() {
      return this.route === 'login'
    },
    isRegistration() {
      return this.route === 'registration'
    },
    isForgotPassword() {
      return this.route === 'forgot-password'
    },

    ...mapState([
      'authMethod',
      'idAuthMethod',
      'phoneAuthMethod',
    ])
  }
}

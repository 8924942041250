//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '/app.config'
export default {
  name: "TodaysDocumentsIcon",
  data() {
    return {
      config
    }
  }
}

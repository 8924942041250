//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapMutations} from "vuex";
import Login from "@/components/auth/fields/Login";
import PinCode from "@/components/auth/fields/Pincode";
import AgreeCheckbox from "@/components/main/AgreeCheckbox";
import config from '/app.config';

export default {
  name: "PhoneForm",
  components: {AgreeCheckbox, PinCode, Login},
  props: {
    setError: Function,
    phoneOrEmail: String,
    error: String,
    agreeError: Boolean,
    setAgreeError: Function,
    id: String,
    pin: String,
    setPhoneOrEmail: Function,
    setPin: Function,
    setShowLoader: Function,
    checkResponse: Function,
    setLoginError: Function,
    getLoginError: Function,
    getPasswordError: Function,
    setPasswordError: Function,
    cleanErrors: Function,
  },
  data() {
    return {
      isAgree: false,
      hidePhoneAuth: config.variables && config.variables.hidePhoneAuth,
    }
  },
  methods: {
    setIsAgree(value) {
      this.isAgree = Boolean(value)
    },
    submit(e) {
      e.preventDefault()

      if(!this.loginFilled) {
        this.setError(this.$t("errors.invalid_data_format"))
        this.setLoginError(true)
        return;
      }

      if(!this.isAgree) {
        this.setAgreeError(true)
        return;
      }

      if(!this.pin.length) {
        this.setError(this.$t("errors.invalid_data_format"))
        this.setPasswordError(true)
        return;
      }

      this.setShowLoader(true)

      const params = {
        pin: this.pin
      }

      if (this.isIdAuthMethod) {
        params.id = this.id
      }

      if (this.isPhoneAuthMethod) {
        params.login = this.phoneOrEmail
      }

      const response = this.login(params);

      response.then(async (res) => {
        this.checkResponse(res)

        if (res.data && res.data.result && res.data.result.token) {
          this.setToken(res.data.result.token);
          if(res.data.result.type && res.data.result.type === "worker") {
            this.setAuthMethod("id");
          } else {
            this.setAuthMethod("phone");
            await this.getUserAndStudents()
          }
          await this.getStudentInfo()
          localStorage.edu_app_token = res.data.result.token;
          this.$router.push({
            name: 'courses'
          })
        }
      }).finally(() => {
        this.setShowLoader(false)
      });
    },
    ...mapActions([
      'login',
      'getUserAndStudents',
      'getStudentInfo',
    ]),
    ...mapMutations([
      'setToken',
      'setAuthMethod'
    ]),
  },
  computed: {
    loginFilled() {
      const isPhone = this.phoneOrEmail && this.isPhoneAuthMethod
      const isId = this.id && this.isIdAuthMethod

      return !this.getLoginError() && (isPhone || isId)
    },
    ...mapGetters([
      'isIdAuthMethod',
      'isPhoneAuthMethod',
    ])
  }
}

//
//
//
//
//
//
//
//

export default {
  name: "VideoLesson",
  props: {
    src: String
  }
}

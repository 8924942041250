//
//
//
//
//
//

export default {
    name: 'scroll-to-component',
    methods: {
      scrollTo () {
        this.$refs.component.scrollIntoView();
      },
    },
};

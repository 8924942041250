class StateMachine {
  constructor (initialState, transitions) {
    this.state = initialState
    this.transitions = transitions
  }

  async transition (nextState, withoutCallback = false) {
    if (!this.transitions[nextState]) {
      throw new Error('state not found')
    }

    if (typeof this.transitions[nextState] !== 'function') {
      throw new Error('transition callback not found')
    }

    try {
      if (!withoutCallback) {
        await this.transitions[nextState]()
      }

      this.state = nextState
    } catch (e) {
      console.log(e.message);
      console.log('transition error');
    }
  }

  is (state) {
    return this.state === state
  }
}

export default StateMachine
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MultipleAnswer",
  props: {
    question: Object,
    setAnswer: Function,
    userAnswers: Object,
    rightAnswers: Object,
    isTestPassed: Boolean,
  },

  data() {
    return {
      answers: []
    }
  },

  mounted() {
    if (this.question && this.question.ANSWERS) {
      let answers = [];
      let allAnswers = this.question.ANSWERS;

      allAnswers.forEach((answer) => {
        let isActive = false

        if (this.userAnswers && this.userAnswers[this.question.ID] && this.userAnswers[this.question.ID].includes(answer)) {
          isActive = true
        }

        answers.push({
          active: isActive,
          answer: answer
        })
      })

      this.answers = answers
    }
  },
  methods: {
    click(index) {
      if (this.isTestPassed) {
        return
      }

      let answers = this.answers
      let userAnswers = []

      if (answers[index]) {
        answers[index].active = !answers[index].active
      }

      for (let answer of answers) {
        if (answer.active) {
          userAnswers.push(answer.answer)
        }
      }

      this.answers = answers

      this.setAnswer(this.question.ID, userAnswers)
    }
  },
  computed: {
    rightQuestionAnswers() {
      //todo
      if (!this.question || !this.question.RIGHT_ANSWERS) {
        return []
      }

      return this.question.RIGHT_ANSWERS
    },
  }
}

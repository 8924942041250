//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapMutations, mapState} from "vuex";
  import Loader from "../main/Loader";
  import NavLinks from "../main/NavLinks";
  import Course from "./Course";
  import RestrictedMessage from "@/components/errorBlocks/RestrictedMessage";

  export default {
    name: "Content",
    components: {RestrictedMessage, Course, NavLinks, Loader},
    mounted() {
      //this.getStudentInfo()
      this.getStudentCourses()
    },
    data() {
      return {
        showLoader: false,
        restrictedMessage: ''
      }
    },
    watch: {
      currentStudent(current, prev) {
        if (current.id === prev.id) return;
        this.getStudentCourses();
      }
    },
    methods: {
      getStudentCourses() {
        this.restrictedMessage = ''
        this.showLoader = true
        const response = this.getCourses()

        response.then(res => {
          this.showLoader = false

          if (res.data.error && res.data.error === 401) {
            this.$router.history.push({
              name: 'login'
            });
          }

          if (res.data.error && res.data.error === 403 && res.data.errorStr) {
            this.restrictedMessage = res.data.errorStr
          }

          if (!res.data.result || !res.data.result.courses) {
            return
          }

          const courses = res.data.result.courses;

          this.setCourses(courses);
        }).finally(() => {
          this.showLoader = false
        });
      },
      ...mapActions([
        'getCourses',
        'getStudentInfo',
      ]),
      ...mapMutations([
        'setCourses'
      ])
    },
    computed: {
      isRestrictedCourse() {
        return this.courses.find(course => course.PASSED_LESSONS_COUNT > 1 ) //todo
      },

      ...mapState([
        'courses',
        'currentStudent',
        'showDocumentsFirst',
      ])
    },
  }

//
//
//
//
//

import {mapState} from "vuex";
import notification from "@/notification/notification";

export default {
  name: "IspringTest",
  props: {
    test: Object,
    sessionId: String,
  },
  data() {
    return {
      'valid': false,
    }
  },
  computed: {
    ...mapState([
      'token',
      'currentStudent',
    ]),
  },
  mounted() {
    this.valid = (this.test && this.test.SLIDES_FOLDER && this.test.VALID)
    if (!this.valid) {
      notification.error(this.$t('test.bad_test'))
    }
  }
}

import configFronted from "@/config-frontend.json"

let classes = configFronted.default.classes;
let paths = configFronted.default.paths;
let colors = configFronted.default.colors;
let variables = configFronted.default.variables;
let links = configFronted.default.links;
let icons = configFronted.default.icons;
let siteUrl = process.env.VUE_APP_SITE_URL || '';

if (configFronted[window.location.hostname] !== undefined) {
  let configHostname = configFronted[window.location.hostname];
  if (configHostname.classes) classes = configHostname.classes;
  if (configHostname.paths) paths = configHostname.paths;
  if (configHostname.colors) colors = configHostname.colors;
  if (configHostname.variables) variables = configHostname.variables;
  if (configHostname.links) links = configHostname.links;
  if (configHostname.siteUrl) siteUrl = configHostname.siteUrl;
  if (configHostname.icons) icons = configHostname.icons;
}

export default {
  siteUrl, classes, paths, colors, variables, links, icons
}
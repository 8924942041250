//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DeclinedIcon from "@/components/icons/DeclinedIcon";
export default {
  name: "TheDeclinedRequest",
  components: {DeclinedIcon},
  props: {
    message: String
  }
}

//
//
//
//
//
//

export default {
  name: "TextQuestion",
  props: {
    question: Object,
    number: Number
  }
}

//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';
import moment from "moment";
import api from "@/api/api";
import notification from "@/notification/notification";
import ExternalDocumentsFilters from './components/ExternalDocumentsFilters.vue';
import ExternalDocumentsTable from './components/ExternalDocumentsTable.vue';


export default {
  name: 'ExternalDocuments',
  components: { ExternalDocumentsFilters, ExternalDocumentsTable },
  data() {
    return {
      filter: {},
      selectedDates: [],
      dateFormat: 'DD.MM.YYYY',
    }
  },
  computed: {
    ...mapGetters(['getExternalDocuments'])
  },
  methods: {
    getSignRules() {
      this.setShowDocumentsLoader(true)
      const response = api.getSignRulesForType(this.filter.type)
      response.then((res) => {
        if (res.data.error && res.data.error === 401) {
          this.$router.push({
            name: 'login'
          })
        } else if (res.data.error && res.data.errorStr) {
          notification.error(res.data.errorStr)
        } else {
          this.signRules = res.data.result.rules;
        }
      }).finally(() => {
        this.setShowDocumentsLoader(false)
      }).catch((e) => {
        console.log(e);
      })
    },
    changeDocument(document) {
      let docs = [];
      this.documents.forEach(el => {
        if (el.id == document.id) {
          docs.push(document);
        } else {
          docs.push(el)
        }
      })
      this.documents = docs;
    },
    setFilter(filter) {
      this.filter = filter;
      this.$store.dispatch('getExternalDocuments', this.filter);
    },
    getFilters() {
      this.setShowDocumentsLoader(true);
      const response = api.getDocumentsFilters();

      response.then(res => {
        if (res.data.error && res.data.error === 403 && res.data.errorStr) {
          this.setShowDocumentsLoader(false)
          this.setRestrictedError(res.data.errorStr)
          return
        } else if (res.data.error && res.data.errorStr) {
          this.setShowDocumentsLoader(false);
          notification.error(res.data.errorStr)
          return
        }

        if (!res.data.result || !res.data.result.filters) {
          this.setShowDocumentsLoader(false)
          return
        }

        if (res.data.result.filters.factRealDocDate) {
          this.selectedDates = [
            moment(res.data.result.filters.factRealDocDate.gte, this.dateFormat).toDate(),
            moment(res.data.result.filters.factRealDocDate.lte, this.dateFormat).toDate(),
          ]
        }

        this.$store.dispatch('getExternalDocuments');
      }).catch(() => {
        this.setShowDocumentsLoader(false)
      })
    },
    getDocuments(limit = { external: false }) {
      this.setShowDocumentsLoader(true);
      this.$store.dispatch('getExternalDocuments', limit);
    },
    ...mapMutations([
      'setShowDocumentsLoader',
    ])
  },
  mounted() {
    this.getFilters();
  },

}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from '@/components/main/Loader';

export default {
  name: "TrainingLogin",
  components: {Loader},
  data(){
    return {
      folders: [],
      url: '',
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.isTrialTestLoading;
    }
  },
}

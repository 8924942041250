//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapGetters } from 'vuex';

import Folder from '@/components/trainingNew/components/Folder';

export default {
  name: 'trainings',
  components: {
    Folder,
  },
  data() {
    return {
      tests: [],
      isLoading: false,
    }
  },
  computed: {
    filteredFolders() {
      return this.trialTests.CHILDREN && this.trialTests.CHILDREN.length && this.trialTests.CHILDREN.filter(folder => folder.NAME.toLowerCase().includes(this.$store.getters.normalisedSearch));
    },
    ...mapGetters(['trialTests']),
  },
  methods: {
    ...mapMutations(['setSearchTraining']),
  },
  destroyed() {
    this.setSearchTraining('');
  }
}
